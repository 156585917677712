import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useErrorHandler } from "../../hook/useErrorHandler";
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from "react-query";
import PageTitle from "../../component/pageTitle";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import { LoadingButton } from "@mui/lab";
import { CryptoproCertificateInfo, } from "../../models/interfaces";
import { DocumentService } from "../../service/document.service";
import { SignxSignTypeEnum } from "../../models/enum";
import { useSnackbar } from "notistack";
import { CertificateListItem } from '../../service/cryptopro/interfaces/certificate.list.item';
import { CryptoProWrapper } from '../../service/cryptopro/cryptopro.wrapper';
import { CryptoproError } from '../../service/cryptopro/model/cryptopro.error';

export function TokenSign() {
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [cert, setCert] = useState("");
  const [certInfo, setCertInfo] = useState<CryptoproCertificateInfo | null>(
    null
  );
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const licenses = await CryptoProWrapper.checkLicense();
      switch(state.type) {
        case SignxSignTypeEnum.TOKEN_LONG:
          if (!licenses.OCSP) {
            enqueueSnackbar({
              message: t('tokenSign.licenseError.OCSP'),
              variant: 'warning',
            })
          }
        /* fall-through */
        case SignxSignTypeEnum.TOKEN_TSP:
          if (!licenses.TSP) {
            enqueueSnackbar({
              message: t('tokenSign.licenseError.TSP'),
              variant: 'warning',
            })
          }
        /* fall-through */
        case SignxSignTypeEnum.TOKEN:
          if (!licenses.CSP) {
            enqueueSnackbar({
              message: t('tokenSign.licenseError.CSP'),
              variant: 'warning',
            })
          }
      }
    })();
  }, []);

  const { data: certificates } = useQuery<CertificateListItem[]>("cert", () => CryptoProWrapper.getCertificatesList());
  const { mutateAsync: sign } = useMutation(async (type: SignxSignTypeEnum) => {
    if (!cert || !certInfo) {
      setError(true);
      return;
    }

    setError(false);
    let signature;
    try {
      switch(type) {
        case SignxSignTypeEnum.TOKEN:
          signature = await CryptoProWrapper.signSimple(state.document.originalFile.content, cert);
          break;
        case SignxSignTypeEnum.TOKEN_TSP:
          signature = await CryptoProWrapper.signTSP(state.document.originalFile.content, cert);
          break;
        case SignxSignTypeEnum.TOKEN_LONG:
          signature = await CryptoProWrapper.signOCSP(state.document.originalFile.content, cert);
          break;
        default:
          enqueueSnackbar({ message: t('error.wrongType'), variant: 'error' });
      }
      signature = signature.replaceAll('\r\n', '');
    } catch (e: any) {
      enqueueSnackbar({
        message: (e instanceof CryptoproError) ? e.description : e.message,
        variant: 'error',
      });
      return;
    }

    try {
      await DocumentService.sign(
        state.document.uuid,
        state.document.guid,
        state.employee,
        type,
        '',
        signature,
        certInfo
      );
      await queryClient.refetchQueries(
        `document_details_${state.document.guid}`
      );
      navigate(`/docflow/details/${state.document.guid}`, { replace: true });
    } catch (e) {
      handleError("sign", e);
    } finally {
      setLoading(false);
    }
  });

  return (
    <Box>
      <PageTitle text={t("tokenSign.title")} />
      <Stack direction="column" spacing={2} mt={2}>
        <FormControl size="small" error={error}>
          <InputLabel id="select-label">
            {t("tokenSign.certSelect.label")}
          </InputLabel>
          <Select
            labelId="select-label"
            label={t("tokenSign.certSelect.label")}
            value={cert}
            onChange={async (e) => {
              setCert(e.target.value);
              if (e.target.value) {
                const cert = await CryptoProWrapper.getCertificateInfo(
                  e.target.value
                );
                setCertInfo({
                  name: cert.name,
                  serialNumber: cert.serialNumber,
                  thumbprint: cert.thumbprint,
                  validFromDate: cert.validFromDate,
                  validToDate: cert.validToDate,
                });
              } else {
                setCertInfo(null);
              }
            }}
          >
            <MenuItem value="">
              {t("tokenSign.certSelect.notSelected")}
            </MenuItem>
            {certificates?.map((c) => (
              <MenuItem value={c.thumbprint}>{c.name}</MenuItem>
            ))}
          </Select>
          {error && (
            <FormHelperText>{t("tokenSign.certSelect.error")}</FormHelperText>
          )}
        </FormControl>
        {certInfo && (
          <Box>
            <Typography variant="h5" mb={2}>
              {t("tokenSign.certificateInfo.title")}
            </Typography>
            <Stack direction="column" spacing={1}>
              {Object.keys(certInfo).map(
                (key) => (
                  <Stack direction="row" alignItems="baseline" spacing={1}>
                    <Typography variant="caption">
                      {t(`tokenSign.certificateInfo.${key}`)}:
                    </Typography>
                    <Typography variant="body1">
                      {key.includes("Date")
                        ? new Date(certInfo[key as keyof CryptoproCertificateInfo]!).toLocaleDateString()
                        : certInfo[key as keyof CryptoproCertificateInfo]}
                    </Typography>
                  </Stack>
                )
              )}
            </Stack>
          </Box>
        )}
        <Stack direction="row" spacing={2}>
          <LoadingButton
            title={t(`tokenSign.buttonHint.${state.type}`)}
            loading={loading}
            variant="outlined"
            color="success"
            onClick={() => sign(state.type)}
          >
            {t(`tokenSign.button.TOKEN`)}
          </LoadingButton>
          <Button variant="text" color="primary" onClick={() => navigate(-1)}>
            {t("button.back")}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
