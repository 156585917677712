import { EditableEntityDto } from '../common';
import { DocumentForUpdate } from '../../interfaces/document.for.update';

export class DocumentEditDto extends EditableEntityDto<DocumentForUpdate>{
  title = '';
  crmEntity = {
    type: '',
    id: ''
  };
  queue: any = {};
  file = null;
  rejectForbidden = false;

  constructor() {
    super(undefined);
  }
}