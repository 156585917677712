import { useGridApiContext } from '@mui/x-data-grid/hooks/utils/useGridApiContext';
import { useGridSelector } from '@mui/x-data-grid/hooks/utils/useGridSelector';
import { gridPreferencePanelStateSelector } from '@mui/x-data-grid/hooks/features/preferencesPanel/gridPreferencePanelSelector';
import { GridPreferencePanelsValue } from '@mui/x-data-grid/hooks/features/preferencesPanel/gridPreferencePanelsValue';
import { IconButton } from '@mui/material';
import { FontAwesomeScalableIcon } from '../layout/FontAwesomeScalableIcon';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';

interface IProps {
  panelAnchor: HTMLElement | null;
  setPanelAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setPanelPlacement: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export function TableColumnsButton(props: IProps) {
  const apiRef = useGridApiContext();
  const { open, openedPanelValue } = useGridSelector(apiRef, gridPreferencePanelStateSelector);
  const toggleToolbar = () => {
    if (open && openedPanelValue === GridPreferencePanelsValue.columns) {
      apiRef.current.hidePreferences();
    } else {
      props.setPanelAnchor(props.panelAnchor);
      props.setPanelPlacement('top-end');
      apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
    }
  };

  return (
    <IconButton onClick={toggleToolbar}>
      <FontAwesomeScalableIcon icon={solid('cog')} />
    </IconButton>
  );
}
