import React, { ChangeEvent } from 'react';
import { Rest } from "../rest";
import { AppContext } from "../context/app.context";
import { InputAdornment, Link, Stack, TextField } from '@mui/material';
import i18n from '../i18n';
import FontAwesomeScalableIcon from './layout/FontAwesomeScalableIcon';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SettingsService } from '../service/settings.service';
import { Trans } from 'react-i18next';

interface IState {
    externalSystemClientId: string;
    loading: boolean;
}

export default class ApikeyAuthorization extends React.Component<any, IState> {

    static contextType = AppContext;

    constructor(props: any) {
        super(props);
        this.state = {
            externalSystemClientId: '',
            loading: false,
        }
    }

    componentDidMount() {
        this.refreshData();
    }

    async refreshData() {
        this.setState({
            externalSystemClientId: this.context.settings.ExternalSystemClientId.Value
        }, () => {
            Rest.resizeFrame()
        });
    }

    onChangeExternalSystemClientId = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ loading: true });
        const blockToChange = e.target?.closest('.MuiInputBase-root')?.querySelector('.fa-solid');
        blockToChange?.classList.add("fa-spin");
        const newApiKey = e.target.value;
        const checkInfo = await SettingsService.updateApiKey(newApiKey);
        if (checkInfo !== null) {
            this.context.setAppSettings('ExternalSystemClientId', newApiKey, checkInfo);
        }
        this.setState({
            externalSystemClientId: newApiKey,
            loading: false,
        });

        setTimeout(() => {
            blockToChange?.classList.remove("fa-spin");
          }, 1000);

    }

    render() {
        return (
            <Stack direction="column" spacing={1}>
                <TextField
                  size="small"
                  variant="outlined"
                  label={i18n.t('field-externalSystemClientId')}
                  value={this.state.externalSystemClientId}
                  onChange={(e) => this.onChangeExternalSystemClientId(e)} required
                  InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                            <FontAwesomeScalableIcon icon={this.getIcon()} color={this.getColor()} />
                        </InputAdornment>
                      ),

                  }}
                  fullWidth
                  sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: `${this.getColor()}.main`,
                      borderWidth: this.getColor() ? 2 : 1,
                  }
                }}
                />
                {this.context.isApiKeyLegal && <small><Link href="#" onClick={() => this.context.toggleApiInfoDialog(true)}><Trans>apiKeyDialog.title</Trans></Link></small>}
            </Stack>
        );
    }

    private getColor() {
        if (this.state.externalSystemClientId === '') {
            return undefined;
        } else if (this.state.externalSystemClientId !== '' && this.state.externalSystemClientId !== this.context.settings.ExternalSystemClientId.Value) {
            return 'error';
        } else if (this.state.externalSystemClientId !== '' && this.context.settings.ExternalSystemClientId.Value !== '') {
            return 'success';
        }
    }

    private getIcon() {
        if (this.state.loading) {
            return solid('spinner');
        }

        if (this.state.externalSystemClientId !== '' && this.state.externalSystemClientId !== this.context.settings.ExternalSystemClientId.Value) {
            return solid('xmark');
        } else if (this.state.externalSystemClientId !== '' && this.context.settings.ExternalSystemClientId.Value !== '') {
            return solid('check');
        } else {
            return solid('circle-exclamation');
        }
    }
}