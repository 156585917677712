import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TableColumn } from "../../component/DataTable/table.model";
import { Avatar, Link, Stack } from "@mui/material";
import { Rest } from "../../rest";
import { Employee } from "../../models/interfaces/employee";
import { Check } from "@mui/icons-material";

export function useColumns() {
  const { t } = useTranslation();

  return useMemo(
    () => [
      new TableColumn<Employee>({
        headerName: t("field-user"),
        field: "property_user",
        renderCell: ({ row }) => (
          <Stack direction="row" spacing={2} alignItems="center">
            {row.avatar && <Avatar src={row.avatar} />}
            {row.id ? (
              <Link href={`https://${Rest.domain}/company/personal/user/${row.id}/`} target="_blank">
                {row.name}
              </Link>
            ) : (
              row.name
            )}
          </Stack>
        ),
        minWidth: 270,
      }),
      new TableColumn<Employee>({
        headerName: t("field-position"),
        field: "post",
        width: 150,
      }),
      new TableColumn<Employee>({
        headerName: t("field-email"),
        field: "email",
        renderCell: ({ row }) => (
          <Stack direction="row">
            {row.emailVerified && <Check color="success" fontSize="small" />}
            <span>{row.email}</span>
          </Stack>
        ),
        width: 270,
      }),
      new TableColumn<Employee>({
        headerName: t("field-verification"),
        field: "clientVerified",
        valueFormatter: ({ value }) =>
          t(value ? "field-verification-yes" : "field-verification-no"),
        width: 220,
      }),
      new TableColumn<Employee>({
        headerName: t("field-uuid"),
        field: "uuid",
        width: 180,
      }),
      new TableColumn<Employee>({
        headerName: t("field-certificate"),
        field: "certificate",
        renderCell: ({ row }) =>
          row.certificate ? (
            <Stack direction="column" spacing={1}>
              <span>{row.certificate.serialNumber}</span>
              <span>
                {new Date(row.certificate.validToDate).toLocaleString()}
              </span>
            </Stack>
          ) : null,
        width: 355,
      }),
    ],
    []
  );
}
