import { CryptoproCertificateInfo } from '../models/interfaces';
import { DocumentFileType, SignxSignTypeEnum } from "../models/enum";
import { SignxFileContent } from "../models/interfaces/signx.file.content";
import { CrudService } from './crud.service';
import { EntityRoutes } from '../models/route/entity.routes';
import { HttpClient } from './http.client';

class CDocumentService extends CrudService {


  constructor() {
    super('/document', new EntityRoutes('document'));
  }

  async download(guid: string, type: DocumentFileType, employee?: string) {
    return (
      await HttpClient.post<SignxFileContent>(`/document/file/${guid}`, { type, employee })).data;
  }

  async sign(
    documentUuid: string,
    documentGuid: string,
    employee: string,
    type: SignxSignTypeEnum,
    signCode?: string,
    signature?: string,
    certificateInfo?: CryptoproCertificateInfo
  ) {
    return (await HttpClient.post(`/document/sign`, {
      documentUuid,
      documentGuid,
      employee,
      type,
      cryptoPro: { signature, certificateInfo },
      signCode,
    })).data;
  }

  async reject(
    documentUuid: string,
    documentGuid: string,
    employee: string,
    comment: string
  ) {
    return (await HttpClient.post(`/document/reject`, {
      documentUuid,
      documentGuid,
      employee,
      comment,
    })).data;
  }

  async revoke(uuid: string, employee: string) {
    return (await HttpClient.post(`/document/revoke/${uuid}`, { employee })).data
  }
}

export const DocumentService = new CDocumentService();
