import React, { useContext, useEffect } from 'react';
import { Rest } from '../rest';
import { AppContext } from '../context/app.context';
import { useNavigate } from 'react-router-dom';
import { AccessKey } from '../enum/accessKey';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export function MainPage() {
    const navigate = useNavigate();
    const context = useContext(AppContext);
    const info: boolean | any = Rest.placementInfo();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        if (context.isApiKeyLegal) {
            if (typeof info === 'object' && info.options?.params?.guid) {
                const link = `/docflow/details/${info.options.params?.guid}`;
                if (info.options?.params?.client) {
                    context.setCurrentClient(info.options.params.client);
                }
                Rest.removePlacementOption('params');
                navigate(link);
            } else if (context.access(AccessKey.documents)) {
                console.log('should navigate!')
                navigate('/docflowinternal');
            }
        } else {
            enqueueSnackbar(t("error.apiKeyIncorrect"), { variant: "error" });
            if (context.access(AccessKey.settings)) {
                navigate('/settings');
            }
        }
    }, [context, navigate, t, enqueueSnackbar, info])

    return (<div></div>);
}
