import { WebhookEventType } from '../models/enum';
import { Rest } from '../rest';
import { HttpClient } from './http.client';
import axios from 'axios';
import { SelectItem } from '../models/interfaces';

class CSettingsService {

  async toggleWebhook(registerWebHook: boolean, eventType: WebhookEventType) {
    return HttpClient.post(`/webhook/${registerWebHook ? 'register' : 'remove'}`, { eventType });
  }

  async checkApikeyAuth(clientToken: string): Promise<SelectItem[] | null> {
    const currentUserAuthData = Rest.getAuth();
    if (!currentUserAuthData)
      return null;

    try {
      return (await axios.post<SelectItem[]>('/apikeycheck', { clientToken, ...currentUserAuthData })).data;
    }
    catch (err) {
      console.error('error checkApikeyAuth', err);
      return null;
    }
  }

  async updateApiKey(clientToken: string): Promise<SelectItem[] | null> {
    const currentUserAuthData = Rest.getAuth();
    if (!currentUserAuthData)
      return null;

    try {
      return (await axios.post<SelectItem[]>('/apikeyupdate', { clientToken, ...currentUserAuthData })).data;
    }
    catch (err) {
      console.error('error checkApikeyAuth', err);
      return null;
    }
  }
}
export const SettingsService = new CSettingsService();
