import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import React, { useCallback, useContext, useState } from "react";
import { useErrorHandler } from "../../hook/useErrorHandler";
import { DocumentEditDto } from "../../models/dto/document/document.edit.dto";
import ValidationContext from "../../hook/UseValidation/validation.context";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import InputControl from "../../component/ui/InputControl";
import { Length, NotEmpty } from "../../hook/UseValidation/validators";
import { DocumentEditValidator } from "../../service/validator/document.edit.validator";
import { FileControl } from "../../component/ui";
import AutocompleteControl from "../../component/ui/AutocompleteControl";
import { EntityQueryKey } from "../../enum/query.keys";
import { useOptions } from "../../hook/UseOptions";
import { CrmEntityType, SignxSignTypeEnum } from "../../models/enum";
import SelectControl from "../../component/ui/SelectControl";
import { Rest } from "../../rest";
import { SelectItem } from "../../models/interfaces";
import {
  AutocompleteResponse,
  GuidEntity,
} from "../../models/interfaces/common";
import { UserService } from "../../service/user.service";
import { Delete } from "@mui/icons-material";
import { DocumentService } from "../../service/document.service";
import { useSnackbar } from "notistack";
import { DocumentForUpdate } from "../../models/interfaces/document.for.update";
import { LoadingButton } from "@mui/lab";
import { DocumentMediaTypes } from "../../models/constants";
import CheckboxControl from "../../component/ui/CheckboxControl";
import { AppContext } from "../../context/app.context";
import { FeatureFlagEnum } from "../../models/enum/feature.flag.enum";
import Utils from '../../utils';

interface IProps {
  internal: boolean;
}

export function DocumentEdit({ internal }: IProps) {
  const { featureFlag } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [validateOn, setValidateOn] = useState(false);
  const [validationError, setValidationError] = useState(-1);
  const [validationErrorKeys, setValidationErrorKeys] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [client] = Utils.getFromLocalStorage('client');

  const errorHandler = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();

  const [signer, setSigner] = useState('');
  const [dto, setDto] = useState(new DocumentEditDto());
  const updateDto = (key: keyof DocumentEditDto, val: any) => setDto((prev) => ({ ...prev, [key]: val }));
  const signTypes = useOptions(SignxSignTypeEnum, 'signType.')
    .filter((item) => featureFlag[FeatureFlagEnum.GOSKEY_ENABLED] === true || ![SignxSignTypeEnum.GOS_KEY, SignxSignTypeEnum.GOS_KEY_QES].includes(item.value as SignxSignTypeEnum));
  const { data: smartProcesses } = useQuery([EntityQueryKey.SmartProcess], () => Rest.callMethod('crm.type.list', {}, true), {
    onSuccess: (data) => {
      for (const sp of data.items?.flatMap((item: any) => item.types ?? []) ?? []) {
        types.push({ value: String(sp.entityTypeId), text: sp.title });
      }
    }
  });
  const types = useOptions(CrmEntityType, 'crmEntity.')
    .concat((smartProcesses?.items?.flatMap((item: any) => item.types ?? []) ?? []).map((sp: any) => ({
      value: String(sp.entityTypeId),
      text: sp.title
    })));
  const { data: employees } = useQuery([EntityQueryKey.User, client], () => UserService.list({}));

  const listCrmItems = useCallback(async (type: string, term: string, skip: number | undefined, selected: string): Promise<AutocompleteResponse> => {
    if (!type) return { items: [], nextOffset: 0 };

    let items: SelectItem[];
    const params: any = {
      select: ['ID', 'TITLE'],
      start: skip ?? 0,
    };
    const byId = !isNaN(Number(term)) && String(selected) === String(term)
    if (byId) {
      params.filter = { ID: term };
    } else if (term?.trim()) {
      params.filter = { '%TITLE': term.trim() };
    }

    if (type === CrmEntityType.task) {
      items = (await Rest.callMethod('tasks.task.list', params)).items.map((item: any) => ({ value: String(item.id), text: item.title }));
    } else if (isNaN(parseInt(type))) {
      items = (await Rest.callMethod(`crm.${type}.list`, params)).items.map((item: any) => ({ value: String(item.ID), text: item.TITLE }));
    } else {
      params.select = ['id', 'title'];
      params.entityTypeId = type;
      if (term?.trim()) {
        params.filter = { [byId ? 'id' : '%title']: term.trim() };
      }
      items = (await Rest.callMethod('crm.item.list', params)).items.map((item: any) => ({ value: String(item.id), text: item.title }));
    }

    return { items, nextOffset: items.length === 50 ? (skip ?? 0) + 50 : false };
  }, []);

  const handleNext = () => {
    setValidateOn(true);
    setValidationError(-1);
    const errors = DocumentEditValidator.validate(dto, activeStep);
    if (errors.length === 0) {
      const currentStep = activeStep;
      if (activeStep < 3) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      setValidateOn(false);
      setValidationErrorKeys([]);
      if (currentStep >= 2) {
        return handleSend();
      }
    } else {
      setValidationError(activeStep);
      if (activeStep === 1)
        setValidationErrorKeys(errors);
    }
  }

  const handleSend = async () => {
    setLoading(true);
    try {
      const result = await DocumentService.save<DocumentForUpdate, GuidEntity>({
        ...dto,
        queue: Object.values(dto.queue),
      } as any);
      enqueueSnackbar(
        t('documentCreate.success'),
        { variant: "success" }
      );
      navigate(`/docflow${internal ? 'internal' : ''}/details/${result.guid}`)
    } catch (e) {
      errorHandler('send doc', e);
    } finally {
      setLoading(false);
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <ValidationContext.Provider value={{ validateOn }}>
      <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
        <Step>
          <StepLabel error={validationError === 0}>
            {t('documentCreate.step.info')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel error={validationError === 1}>
            {t('documentCreate.step.signers')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel error={validationError === 2}>
            {t('documentCreate.step.file')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel error={validationError === 3}>
            {t('documentCreate.step.send')}
          </StepLabel>
        </Step>
      </Stepper>
      {(() => {
        switch (activeStep) {
          case 0:
            return (<Box sx={{ maxLength: '500px' }}>
              <InputControl required labelKey="documentCreate.field.title" value={dto.title} onChange={(val) => updateDto('title', val)}
                            validators={[NotEmpty, Length(255)]} />
              <SelectControl options={types} labelKey="documentCreate.field.crmEntityType" value={dto.crmEntity.type}
                             onChange={(val) => {
                               updateDto('crmEntity', { ...dto.crmEntity, type: val, id: '' });
                             }} />
              <AutocompleteControl required={!!dto.crmEntity?.type} disabled={!dto.crmEntity.type} value={dto.crmEntity.id} entity={EntityQueryKey.CrmEntityType}
                                   auxQueryKey={dto.crmEntity.type}
                                   labelKey="documentCreate.field.crmEntity"
                                   onChange={(val) => updateDto('crmEntity', { ...dto.crmEntity, id: val })}
                                   options={(queryKey, term, skip) => listCrmItems(dto.crmEntity.type, String(term), skip, dto.crmEntity.id)}
                                   validators={!dto.crmEntity?.type ? [] : [NotEmpty]}/>
              {featureFlag[FeatureFlagEnum.FORBID_SIGN_ENABLED] === true && <CheckboxControl labelKey="documentCreate.field.rejectForbidden" value={dto.rejectForbidden}
                             onChange={(val) => updateDto('rejectForbidden', val)} />}
            </Box>);
          case 1:
            return (<Stack direction="column" spacing={3}>
              <Stack direction="row" spacing={2}>
                <SelectControl
                  options={employees?.items?.filter((item: any) => !Object.keys(dto.queue).includes(item.uuid)).map((item: any) => ({
                    value: item.uuid,
                    text: item.name
                  })) ?? []}
                  labelKey="documentCreate.field.signer"
                  value={signer}
                  onChange={(val) => setSigner(String(val))} />
                {signer && <Button variant="text" color="primary" onClick={() => {

                  updateDto('queue', {
                    ...dto.queue,
                    [signer]: {
                      employee: signer,
                      order: Math.max(0, ...Object.values(dto.queue).map((item: any) => (item.order as number))),
                      signType: SignxSignTypeEnum.SIGNX,
                      initiator: false,
                    }
                  });
                  setSigner('');
                }}>
                  {t("documentCreate.button.addSigner")}
                </Button>}
              </Stack>
              <Stack direction="column" spacing={3}>
                {Object.values(dto.queue).map((item: any, index: number) =>
                  (<Stack direction="row" spacing={2} alignItems="center" sx={{ borderBottom: '1px solid #eee' }}>
                    <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} variant="outlined" size="small"
                               sx={{ width: '50px' }} value={item.order < 0 ? '' : item.order + 1}
                               onChange={(event) => {
                                 if (event.target.value === '') {
                                   updateDto('queue', {
                                     ...dto.queue,
                                     [item.employee]: {
                                       ...item,
                                       order: -1,
                                     }
                                   });
                                   return;
                                 }

                                 let newVal = (Number(event.target.value) || 1) - 1;
                                 if (newVal < 0) return;

                                 const queue: any[] = Object.values(dto.queue);
                                 const maxOrder = Math.max(...queue.map((item) => item.order)) + 1;
                                 if (newVal > maxOrder) return;

                                 if (queue[index + 1] !== undefined && queue[index + 1].order < newVal) {
                                   newVal = queue[index + 1].order;
                                 }

                                 updateDto('queue', {
                                   ...dto.queue,
                                   [item.employee]: {
                                     ...item,
                                     order: newVal,
                                   }
                                 })
                               }} />
                    <span>{employees?.items.find((emp: any) => emp.uuid === item.employee).name}</span>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <span>{t('documentCreate.field.initiator')}</span>
                      <Switch checked={item.initiator} onChange={(event, checked) => {
                        const updated = { ...dto.queue };
                        for (const key of Object.keys(updated)) {
                          if (key === item.employee) {
                            updated[key].initiator = checked;
                          } else {
                            updated[key].initiator = false;
                          }
                        }
                        updateDto('queue', updated);
                      }} />
                    </Stack>
                    <SelectControl options={signTypes} labelKey="documentCreate.field.signType" value={item.signType} onChange={(val) => {
                      updateDto('queue', {
                        ...dto.queue,
                        [item.employee]: {
                          ...item,
                          signType: val,
                        }
                      });
                    }} sx={{ width: '300px' }} />
                    <IconButton onClick={() => {
                      const updated = { ...dto.queue };
                      delete updated[item.employee];
                      updateDto('queue', updated);
                    }
                    }>
                      <Delete color="error" />
                    </IconButton>
                  </Stack>)
                )}
              </Stack>
            </Stack>)
          case 2:
            return (<Box sx={{ maxLength: '500px' }}>
              <FileControl accept={DocumentMediaTypes.join(',')} labelKey="documentCreate.field.file" value={dto.file ? [dto.file] : []}
                           onChange={(val) => updateDto('file', val?.[0] ?? null)} />
            </Box>);
          case 3:
            return (<Box sx={{ p: 5, textAlign: 'center' }}>
              {loading && <CircularProgress size={100} />}
            </Box>)
          default:
            return null;
        }
      })()}
      {validateOn && validationErrorKeys.length > 0 && <Typography sx={{ my: 2 }} color="error">
        {validationErrorKeys.map((key) => <>{t(`documentCreate.error.${key}`)}<br /></>)}
      </Typography>}
      <Stack direction="row" spacing={2} mt={2}>
        <>
          {activeStep > 0 && activeStep < 3 && <Button variant="text" color="primary" onClick={() => handleBack()}>
            {t("documentCreate.button.back")}
          </Button>}
        </>
        <>
          {activeStep < 2 && <Button variant="outlined" color="primary" onClick={() => handleNext()} style={{ marginLeft: 'auto' }}>
            {t("documentCreate.button.next")}
          </Button>}
        </>
        <>
          {activeStep > 1 &&
            <LoadingButton loading={loading} variant="outlined" color="success" onClick={() => handleNext()} style={{ marginLeft: 'auto' }}>
              {t("documentCreate.button.send")}
            </LoadingButton>}
        </>
      </Stack>
    </ValidationContext.Provider>
  );
}