import { ITableColumn, TableColumn, TableGroupAction, TableRowAction } from './table.model';
import { useMemo } from 'react';
import { GridRowSelectionModel, GridValidRowModel } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FilterItem } from '../../models/filter';
import { FilterTypeEnum } from '../../models/filter/enum/filter.type.enum';

//-------------------------------- FILTER --------------------------------
export function getFilterModel(filter: FilterItem[] | undefined, setToDefault = false) {
  return filter?.reduce((acc, item) => {
    if (item.type === FilterTypeEnum.HIDDEN) {
      acc[item.field] = item.value;
    } else {
      acc[item.field] = setToDefault ? item.defaultValue : item.value ?? item.defaultValue;
      acc[`${item.field}View`] = setToDefault ? item.defaultView : item.view ?? item.defaultValue;
    }
    return acc;
  }, {} as any);
}

export function filterHasValue(item: FilterItem) {
  if (item.type === FilterTypeEnum.HIDDEN) {
    return false;
  }
  if (item.type === FilterTypeEnum.DATETIME_RANGE) {
    return !!item.value?.startDate || !!item.value?.endDate;
  }
  return item.value && item.value !== 'null';
}

//------------------------------------------------------------------------

//---------------------------- COMMON COLUMNS ----------------------------
export function useBoolTableColumn<T extends GridValidRowModel>(column: ITableColumn<T>) {
  const { t } = useTranslation();
  return new TableColumn<T>({
    ...column,
    valueFormatter: ({ value }) => t(`common:${value ? 'YES' : 'NO'}`),
    width: column.width ?? 100,
    align: 'center',
  });
}

//------------------------------------------------------------------------

//---------------------------- COMMON ACTIONS ----------------------------
interface RowActionsOptions<T> {
  edit?: Pick<TableRowAction<T>, 'hide' | 'confirm'> & { action: (row: T) => string };
  remove?: Pick<TableRowAction<T>, 'action' | 'hide' | 'confirm'>;
  toggleActive?: Pick<TableRowAction<T>, 'action' | 'hide' | 'confirm'>;
  other?: TableRowAction<T>[];
}

export function useRowActions<T extends GridValidRowModel>(options: RowActionsOptions<T>, memoDeps?: any[]) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMemo(() => {
    let items: TableRowAction<T>[] = [];

    if (options.edit) {
      items.push({
        value: 'edit',
        text: t('common:button.edit') ?? 'no-locale',
        action: (row: T) => navigate(options.edit ? options.edit.action(row) : ''),
        keepDataAfterAction: true,
        hide: options.edit.hide,
      });
    }

    if (options.other) {
      items = items.concat(options.other);
    }

    if (options.toggleActive) {
      items.push({
        value: 'toggleActive',
        text: (row: T) => t(`common:button.toggleActive.${!(row as any).isActive}`),
        action: (row: T) => (options.toggleActive ? options.toggleActive.action(row) : Promise.resolve()),
        hide: (row: T) =>
          !('isActive' in row) || (options.toggleActive?.hide !== undefined && options.toggleActive.hide(row)),
        confirm: options.toggleActive.confirm,
      });
    }

    if (options.remove) {
      items.push({
        value: 'delete',
        text: t('common:button.delete') ?? 'no-locale',
        action: (row: T) => (options.remove ? options.remove.action(row) : Promise.resolve()),
        hide: options.remove.hide,
      });
    }

    return items;
  }, memoDeps ?? []);
}

interface GroupActionsOptions {
  remove?: ((selected: GridRowSelectionModel) => Promise<any>) | null;
  setActive?: ((selected: GridRowSelectionModel) => Promise<any>) | null;
  removeActive?: ((selected: GridRowSelectionModel) => Promise<any>) | null;
  other?: TableGroupAction[];
}

export function useGroupActions(
  { remove, setActive, removeActive, other = [] }: GroupActionsOptions,
  ...memoDeps: any[]
) {
  const { t } = useTranslation();
  return useMemo(() => {
    const items = other;
    if (setActive) {
      items.push({
        value: 'setActive',
        text: t('common:button.toggleActive.true'),
        action: (selected) => setActive(selected),
      });
    }
    if (removeActive) {
      items.push({
        value: 'removeActive',
        text: t('common:button.toggleActive.false'),
        action: (selected) => removeActive(selected),
      });
    }
    if (remove) {
      items.push({
        value: 'delete',
        text: t('common:button.delete'),
        action: (selected) => remove(selected),
      });
    }
    return items;
  }, [...(memoDeps ?? [])]);
}

//------------------------------------------------------------------------
