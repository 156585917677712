import React from 'react';
import { Trans } from 'react-i18next';
import { Rest } from '../rest';

export default class DocflowPage extends React.Component {
    constructor(props) {
        super();
    }

    render() {
        return (
            <div>
                Docflow
            </div>
        );
    }
}