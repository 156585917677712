import axios from "axios";
import { FeatureFlag } from "../models/interfaces";

class CFeatureFlagService {
  async list(): Promise<Record<string, boolean>> {
    return (await axios.get("/feature-flag")).data.reduce(
      (res: Record<string, boolean>, flag: FeatureFlag) => {
        res[flag.name] = flag.value;
        return res;
      },
      {}
    );
  }
}

export const FeatureFlagService = new CFeatureFlagService();
