enum ValidationRule {
   NotEmpty,
   Length,
   Capacity,
   Custom,
   MinMax,
   IsEmail,
   StrongPassword,
   IsNumberString,
}

export default ValidationRule