import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputControl, { IProps } from '../InputControl';
import { useState } from 'react';

export function PasswordControl(props: Omit<IProps, 'type'>) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <InputControl
      {...props}
      type={showPassword ? 'text' : 'password'}
      inputProps={{
        ...(props?.inputProps ?? {}),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((prev) => !prev)}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
