import React from 'react';
import Draggable, { DraggableData } from 'react-draggable';
import { Document, Page } from 'react-pdf';
import i18next from 'i18next';
import { Rest } from '../rest';
import ApiSignature from '../models/apiSignature';
import '../css/sign.css';
import { Button, ButtonGroup, Checkbox, FormControlLabel } from '@mui/material';

interface IProps {
    file: any
    signatures: any[]
    setSignatures: (signatures: any[]) => {}
}

interface IState {
    pageCount: number
    pageNumber: number
}

export default class SignPosition extends React.Component<IProps, IState> {
    documentContainer: any;
    constructor(props: IProps) {
        super(props);
        this.state = {
            pageCount: 0,
            pageNumber: 1
        };

        this.documentContainer = React.createRef();
    }

    onDocumentLoadSuccess = (e: any) => {
        console.log('onDocumentLoadSuccess', e);
        this.setState({
            pageCount: e.numPages,
            pageNumber: 1
        }, () => {
            Rest.resizeFrame();
        });
    }

    handleDrag = (e: any, data: DraggableData, order: number) => {
        const { pageNumber } = this.state;
        const { signatures } = this.props;
        const index = signatures.findIndex(x => x.page === pageNumber && x.order === order);
        if (index > -1) {
            signatures[index].onMove(data.x, data.y);
            this.props.setSignatures(signatures);
        }
    }

    pageLeft = () => {
        const { pageNumber } = this.state;
        this.setState({
            pageNumber: pageNumber - 1 > 1 ? pageNumber - 1 : 1
        });
    }

    pageRight = () => {
        const { pageNumber, pageCount } = this.state;
        this.setState({
            pageNumber: pageNumber + 1 > pageCount ? pageCount : pageNumber + 1
        });
    }

    onAddSignature = () => {
        const pageSignatures = this.getSignaturesForCurrentPage();

        const { pageNumber } = this.state;
        const { signatures } = this.props;
        if (pageSignatures.length > 0) {
            this.props.setSignatures(signatures.filter(x => x.page !== pageNumber));
        }
        else {
            const documentSize = this.documentContainer.current.getBoundingClientRect();
            signatures.push(new ApiSignature(1, 0, 0, pageNumber, documentSize.width, documentSize.height));
            signatures.push(new ApiSignature(2, 120, 0, pageNumber, documentSize.width, documentSize.height));
            this.props.setSignatures(signatures);
        }
    }

    getSignaturesForCurrentPage() {
        const { pageNumber } = this.state;
        const { signatures } = this.props;
        return signatures.filter(x => x.page === pageNumber);
    }

    render() {
        const pageSignatures = this.getSignaturesForCurrentPage();

        return (
            <>
                <div className="d-flex mb-3">
                    {this.state.pageCount > 1 &&
                        <ButtonGroup className="me-4">
                            <Button onClick={this.pageLeft}><i className="fas fa-chevron-left"></i></Button>
                            <Button>{this.state.pageNumber} / {this.state.pageCount}</Button>
                            <Button onClick={this.pageRight}><i className="fas fa-chevron-right"></i></Button>
                        </ButtonGroup>
                    }
                    <div className="mt-1 ms-1">
                        <FormControlLabel control={<Checkbox id='add-signature' name='add-signature' onChange={this.onAddSignature} checked={pageSignatures.length > 0}/>} label={i18next.t('btn-add-signature')} />
                    </div>
                </div>
                <div className="sign-positions border" ref={this.documentContainer}>
                    <Document file={this.props.file} onLoadSuccess={this.onDocumentLoadSuccess}>
                        <Page className="d-inline-block" pageNumber={this.state.pageNumber} />
                    </Document>
                    {pageSignatures.map(item => (
                        <Draggable
                            key={`${item.page}_${item.order}`}
                            bounds="parent"
                            position={{ x: item.getX(), y: item.getY() }}
                            onStop={(e, d) => this.handleDrag(e, d, item.order)}>
                            <span className="border border-primary sign-position-item" title={item.getTitle()} style={{ top: 0, left: 0, width: item.width, height: item.height }}>
                                <div className="title">ПОДПИСАНО {item.order}</div>
                                <div>ФИО</div>
                                <div>email</div>
                                <div>Дата</div>
                                <div className="title">Электронная подпись</div>
                            </span>
                        </Draggable>
                    ))}
                </div>
            </>
        );
    }
}