class ApiSignature {
    page: number;
    posX: number;
    posY: number;
    posXEnd: number;
    posYEnd: number;
    height: number = 42;
    width: number = 99;
    order: number;
    documentWidth: number;
    documentHeight: number;

    constructor(order: number, posX: number, posY: number, page: number, documentWidth: number, documentHeight: number) {
        this.order = order;
        this.posX = posX;
        this.posXEnd = posX + this.width;
        this.posY = posY;
        this.posYEnd = posY + this.height;
        this.page = page;
        this.documentWidth = documentWidth;
        this.documentHeight = documentHeight;
    }

    getX(): number {
        return this.posX;
    }

    getY(): number {
        return this.documentHeight - this.posY - this.height;
    }

    onMove(x: number, y: number) {
        this.posX = x;
        this.posXEnd = x + this.width;
        this.posY = this.documentHeight - y - this.height;
        this.posYEnd = this.documentHeight - y;
    }

    getTitle(): string {
        return `x:${this.posX}_${this.posXEnd} y:${this.posY}_${this.posYEnd}`;
    }
}

export default ApiSignature;