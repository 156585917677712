import ValidationRule from "../validation.rule.enum";
import IValidator from "./validator";

export default class NotEmptyValidator implements IValidator<any|any[]> {
   rule = ValidationRule.NotEmpty;
   validate(value: any|any[]) {
      let valid = true;
      switch (typeof value) {
         case 'object':
            if (Array.isArray(value) && !value.length) {
               valid = false
            } else {
               valid = value != null
            }
            break;
         case 'string':
            if (value === 'null' || !value.trim().length) {
               valid = false;
            }
            break;
         case 'undefined':
            valid = false;
            break;
      }

      return valid ? null : ['validation.required'] as [string, ...any[]]
   }
}