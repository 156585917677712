import { TabProps } from "./tab.props";
import { useTranslation } from "react-i18next";
import { Box, Stack, Typography, useTheme } from '@mui/material';
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Rest } from "../../rest";
import { DocumentHistoryTypeEnum } from '../../models/enum';
import Utils from '../../utils';

export function HistoryTab({ index, tab, document }: TabProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  return index === tab ? (
    <Stack direction="column" spacing={2}>
      {document.history
        .sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
        .map((item) => (
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Typography fontWeight="bold">
              {t(`documentHistory.${item.type}.type`)}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              {item.oldStatus && (<>
                <span style={{ color: theme.palette[Utils.getColor(item.oldStatus)].main }}>
                  {t(`documentHistory.${item.type}.${item.oldStatus}`)}
                </span>
                <ArrowRightAltIcon sx={{ mx: 3 }} />
              </>)}
              <span  style={{ color: item.newStatus && item.type === DocumentHistoryTypeEnum.DOCUMENT_STATUS_CHANGE ? theme.palette[Utils.getColor(item.newStatus)].main : undefined}}
                dangerouslySetInnerHTML={{
                  __html: t(`documentHistory.${item.type}.${item.newStatus}${(item.type === DocumentHistoryTypeEnum.DOCUMENT_STATUS_CHANGE || item.employee.id) ? '' : '_noLink'}`, {
                    ...[item.employee.name, item.employee.id, Rest.domain, item.comment],
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            </Stack>
            <Typography fontSize="small" variant="body2">
              {new Date(item.date).toLocaleString()}
            </Typography>
          </Box>
        ))}
    </Stack>
  ) : null;
}
