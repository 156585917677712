import React, { useCallback, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Rest } from "../rest";
import { useQuery } from "react-query";
import { useErrorHandler } from "../hook/useErrorHandler";
import { FormControlLabel, FormGroup, Grid, Switch, Typography } from '@mui/material';
import { ROBOTS } from '../enum/robots';
import {
  DOC_STATUS_ROBOT_DATA,
  SEND_TO_SIGN_ROBOT_DATA,
  SIGN_REPORT_ROBOT_DATA,
  SEARCH_USER_ROBOT_DATA, FIND_CLIENT_ROBOT_DATA, SIGN_DOC_ROBOT_DATA,
} from '../service/robot.data';
import { AppContext } from '../context/app.context';

export function RobotAdding() {
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const { apiClients } = useContext(AppContext)

  const { data: items, refetch } = useQuery<{ items: string[] }, any, string[]>(
    ["robot-items", apiClients],
    () => Rest.callMethod("bizproc.robot.list"),
    {
      select: (data: { items: string[] }) => data?.items ?? [],
      onError: (error) => handleError("list robots", error),
    }
  );

  const { data: sps } = useQuery('sp_types', () => Rest.callMethod('crm.type.list', {}, true), {
    select: (data) => data.items?.flatMap((item: any) => item.types ?? []) ?? []
  });

  const addRobot = useCallback(
    async (
      add: boolean,
      code: string,
      data: any,
    ) => {
      try {
        if (add) {
          await Rest.callMethod("bizproc.robot.add", data);
        } else {
          await Rest.callMethod("bizproc.robot.delete", { CODE: code, });
        }
        await refetch();
      } catch (err) {
        handleError(
          add ? "addSearchUserInPortal" : "deleteSearchUserInPortal",
          err
        );
      }
    },
    [handleError, refetch]
  );

  const toggleSearchUserInPortal = useCallback(
    async (add: boolean) =>
      addRobot(add, ROBOTS.SEARCH_USER, SEARCH_USER_ROBOT_DATA(apiClients)),
    [addRobot, apiClients]
  );

  const toggleSendDocToSign = useCallback(
    async (add: boolean) =>
      addRobot(add, ROBOTS.SEND_TO_SIGN, SEND_TO_SIGN_ROBOT_DATA(sps, apiClients)),
    [sps, addRobot, apiClients]
  );

  const toggleGetDocStatus = useCallback(
    async (add: boolean) =>
      addRobot(add, ROBOTS.DOC_STATUS, DOC_STATUS_ROBOT_DATA(apiClients)),
    [addRobot, apiClients]
  );

  const toggleGetSignReport = useCallback(
    async (add: boolean) =>
      addRobot(add, ROBOTS.SIGN_REPORT, SIGN_REPORT_ROBOT_DATA(apiClients)),
    [addRobot, apiClients]
  );

  const toggleFindClient = useCallback(
    async (add: boolean) =>
      addRobot(add, ROBOTS.FIND_CLIENT, FIND_CLIENT_ROBOT_DATA(apiClients)),
    [addRobot, apiClients]
  );

  const toggleSignDoc = useCallback(
    async (add: boolean) =>
      addRobot(add, ROBOTS.SIGN_DOC, SIGN_DOC_ROBOT_DATA(apiClients)),
    [addRobot, apiClients]
  );

  return items
    ? (
        <Grid direction="row" xs={12} mt={3}>
          <Grid item xs={12}><Typography variant="h5">{t("robot-manager")}</Typography></Grid>
          <FormGroup sx={{ display: 'inline-flex' }}>
            <RobotFieldView checked={items.includes(ROBOTS.FIND_CLIENT)} label="user-field-add-find-client" onChange={toggleFindClient} />
            <RobotFieldView checked={items.includes(ROBOTS.SEARCH_USER)} label="user-field-add-search-user" onChange={toggleSearchUserInPortal} />
            <RobotFieldView checked={items.includes(ROBOTS.SEND_TO_SIGN)} label="user-field-add-send-doc" onChange={toggleSendDocToSign} />
            <RobotFieldView checked={items.includes(ROBOTS.DOC_STATUS)} label="user-field-add-get-doc-status" onChange={toggleGetDocStatus} />
            <RobotFieldView checked={items.includes(ROBOTS.SIGN_REPORT)} label="user-field-add-get-sign-report" onChange={toggleGetSignReport} />
            <RobotFieldView checked={items.includes(ROBOTS.SIGN_DOC)} label="user-field-add-get-sign-document" onChange={toggleSignDoc} />
          </FormGroup>
        </Grid>
      )
    : null;
}

function RobotFieldView(props: {
  checked: boolean;
  label: string;
  onChange: (checked: boolean) => Promise<void>;
}) {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      control={
        <Switch
          checked={props.checked}
          onChange={(e) => props.onChange(e.target.checked)}
        />
      }
      label={t(props.label)}
    />
  );
}
