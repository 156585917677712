import { useTranslation } from 'react-i18next';
import { SelectItem } from '../../models/interfaces';

export function useActions(internal: boolean | undefined): [SelectItem[]] {
  const { t } = useTranslation();
  const urlPrefix = internal ? 'docflowinternal' : 'docflow';

  const toolbarActions = [
    {
      value: `/${urlPrefix}/create`,
      text: t('documentCreate.button.add'),
    },
  ];

  return [toolbarActions];
}
