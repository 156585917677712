import React, { useCallback, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../context/app.context';
import { AccessKey } from '../enum/accessKey';
import '../css/header.css';
import { Avatar, Box, IconButton, InputBase, Link, MenuItem, Select, SelectChangeEvent, Stack, Tab, Tabs } from '@mui/material';
import { SelectItem } from '../models/interfaces';
import { Rest } from '../rest';
import styled from '@emotion/styled';
import { HelpOutline } from "@mui/icons-material";
import { styled as mstyled } from '@mui/material/styles';

interface IProps {
    apiClients: SelectItem[];
}

const StyledMuiMenuItem = styled(MenuItem)`
  word-break: normal;
  white-space: nowrap;
  z-index: 2000;
  text-overflow: ellipsis;
  padding-bottom: 0;
`;

const StyledSelectInput = mstyled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    paddingBottom: 0,
  },
}));

export function Header({ apiClients }: IProps) {
  const context = useContext(AppContext);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentUser] = useState(context.getCurrentUser());

  const changeClient = useCallback((client: string) => {
    context.setCurrentClient(client);
    navigate('/');
  }, [context, navigate]);

  return (
    <>
      <header className="row">
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider", width: '100%' }}>
                  <Tabs
                    value={/^(\/\w+)\/?/.exec(location.pathname)?.[1] ?? "/"}
                    onChange={(e, val) => navigate(val)}
                    className="col-12"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                      {/*<Tab className="px-2 text-transform-none" value="/docflow" label={t('page-docflow')}/>*/}
                      {context.access(AccessKey.documents) && (
                        <Tab
                          onClick={() => {
                              if (location.pathname !== "/docflowinternal") {
                                  navigate("/docflowinternal");
                              }
                          }}
                          className="px-2 text-transform-none"
                          value="/docflowinternal"
                          label={t("page-internal-docflow")}
                        />
                      )}
                      {context.access(AccessKey.employees) && [
                          context.isApiKeyLegal ? (
                            <Tab
                              className="px-2 text-transform-none"
                              value="/users"
                              label={t("page-users")}
                            />
                          ) : null,
                      ]}
                      {context.access(AccessKey.settings) && [
                          <Tab
                            className="px-2 text-transform-none"
                            value="/settings"
                            label={t("page-settings")}
                          />,
                      ]}
                  </Tabs>
              </Box>
              <Box sx={{ width: '300px', flexShrink: 0 }}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar src={currentUser.PERSONAL_PHOTO} sx={{ width: 48, height: 48 }}/>
                    <Stack direction="column" sx={{ overflow: 'hidden' }}>
                      <Link href={`https://${Rest.domain}/company/personal/user/${currentUser.ID}/`} target="_blank"
                            sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {[currentUser.LAST_NAME, currentUser.NAME, currentUser.SECOND_NAME].filter((s) => !!s).join(' ') || currentUser.EMAIL}
                      </Link>
                      {apiClients?.length > 0 && <Stack direction="row" alignItems="center" sx={{ mt: '5px' }}>
                        {apiClients.length > 1
                          ? <Select value={context.currentClient} variant="standard" size="small" sx={{ maxWidth: '250px', fontSize: '.8rem' }}
                                    onChange={(event: SelectChangeEvent) => changeClient(event.target.value)} input={<StyledSelectInput />}>
                            {apiClients.map((option, idx) => (
                              <StyledMuiMenuItem key={idx} value={option.value}>
                                {t(option.text)}
                              </StyledMuiMenuItem>
                            ))}
                          </Select>
                          : <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '.8rem', marginRight: '5px', color: 'rgba(0, 0, 0, 0.87)', letterSpacing: '0.00938em' }}>{apiClients[0].text}</span>
                        }
                        <IconButton onClick={() => context.toggleApiInfoDialog(true)} sx={{ p: 0 }} title={t('apiKeyDialog.title')}>
                          <HelpOutline color="primary" sx={{ fontSize: '1rem' }} />
                        </IconButton>
                      </Stack>}
                    </Stack>
                  </Stack>
              </Box>
          </Stack>
      </header>
    </>
  );
}