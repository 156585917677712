import ValidationRule from '../validation.rule.enum';
import IValidator from './validator';
import validator from 'validator';
import isEmail = validator.isEmail;

export class IsEmailValidator implements IValidator<string> {
  rule = ValidationRule.IsEmail;

  validate(value: string) {
    return isEmail(value) ? null : (['validation.email'] as [string, ...any[]]);
  }
}
