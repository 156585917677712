import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, IconButton, Radio, RadioGroup, Stack, Typography,
} from '@mui/material';
import { useTranslation } from "react-i18next";
import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { AppContext } from '../../context/app.context';
import { Close, ContentCopy } from "@mui/icons-material";
import { useSnackbar } from 'notistack';


interface IProps {
  isOpen: boolean;
}

export function ApiKeyInfoModal({ isOpen }: IProps) {
  const { t } = useTranslation();
  const { apiClients, availableClients, currentClient, setCurrentClient, toggleApiInfoDialog } = useContext(AppContext);
  const onClose = useCallback(() => toggleApiInfoDialog(false), [toggleApiInfoDialog]);
  const available = useMemo(() => Array.isArray(availableClients) ? availableClients.map((c) => c.value) : [], [availableClients]);
  const { enqueueSnackbar } = useSnackbar();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const  createLink = useCallback((e: any, guid: string) => {
    let copied = false;
    if (textAreaRef.current) {
      textAreaRef.current.value = guid;
      textAreaRef.current.select();
      copied = document.execCommand('copy');
      e.target.focus();
    }
    if (copied) {
      enqueueSnackbar(t('apiKeyDialog.copyGuid.success'), { variant: 'success' });
    } else {
      enqueueSnackbar(t('apiKeyDialog.copyGuid.fail', [guid]), { variant: 'success', autoHideDuration: null });
    }
  }, [t, enqueueSnackbar, textAreaRef]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t("apiKeyDialog.title")}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent style={{ paddingBottom: 0 }}>
        <RadioGroup value={currentClient} onChange={(e) => setCurrentClient(e.target.value)}>
          <Stack direction="column" spacing={1}>
            {apiClients?.map((client) =>
              <Stack direction="row"
                     sx={{ border: 2, borderRadius: 1, borderColor: client.value === currentClient ? 'primary.main' : 'grey.500', p: 1, pl: apiClients?.length > 1 ? 0 : 1, alignItems: 'center' }}>
                {apiClients?.length > 1 && <Radio value={client.value} size="small" style={{ visibility: available.includes(String(client.value)) ? 'visible' : 'hidden' }} />}
                <Stack direction="column">
                  <Typography fontWeight="bold" lineHeight={1} component="span">{client.text}</Typography>
                  <Typography variant={"body2"} lineHeight={1} component="small">
                    guid: {client.value}
                    <IconButton onClick={(e) => createLink(e, String(client.value))} sx={{ ml: 1, p: 0 }} disableRipple>
                      <ContentCopy  sx={{ fontSize: '1rem' }}/>
                    </IconButton>
                  </Typography>
                  {client.attrs?.notAdmin === true && <Typography variant="body2" color="error.main" lineHeight={1} component="small">{t('apiKeyDialog.notAdmin')}</Typography>}
                </Stack>
              </Stack>
            )}
          </Stack>
        </RadioGroup>
        <textarea ref={textAreaRef} style={{ position: 'fixed', top:'0', left: '0', width: '2em', height: '2em', padding: '0', border: 'none', outline: 'none', boxShadow: 'none', background: 'transparent' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("button.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
