import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import React, { useState } from 'react';
import { UserService } from '../../service/user.service';
import { useCurrentUser } from '../../hook/useCurrentUser';
import { useErrorHandler } from '../../hook/useErrorHandler';
import { Loader } from '../../component/loader';
import PageTitle from '../../component/pageTitle';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import ValidationContext from '../../hook/UseValidation/validation.context';
import { Length, NotEmpty, StrongPassword } from '../../hook/UseValidation/validators';
import { LoadingButton } from '@mui/lab';
import { SignCodeUpdateValidator } from '../../service/validator/sign.code.update.validator';
import { Rest } from '../../rest';
import { useSnackbar } from 'notistack';
import { PasswordControl } from '../../component/ui';

export function EmployeeDetails() {
  const { email } = useParams<{ email: string }>();
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const handleError = useErrorHandler();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [validateOn, setValidateOn] = useState(false);

  const { data: employee } = useQuery(
    `employee_details_${email}`,
    () => UserService.details(email ?? currentUser.EMAIL),
    {
      onError: (error) => handleError("Employee details", error),
      onSettled: () => {
        setLoading(false)
      },
    }
  )

  const [loading, setLoading] = useState(!employee);
  const [signCode, setSignCode] = useState('');

  const { mutateAsync: updateSignCode, isLoading: isUpdatingSignCode } = useMutation(async () => {
    setValidateOn(true);
    const errors = SignCodeUpdateValidator.validate(signCode);
    if (errors.length > 0) {
      return;
    }

    try {
      await UserService.updateSignCode(currentUser.EMAIL, signCode || null);
      setSignCode('');
      enqueueSnackbar(
        t('settings.updateSignCodeSuccess'),
        { variant: "success", autoHideDuration: 3000 }
      );
    } catch (e) {
      Rest.scrollParentWindow();
      handleError('updateSignCode', e);
    } finally {
      setValidateOn(false);
    }
  });

  return !employee ? (
    loading ? <Loader /> : <></>
  ) : (
    <Box>
      <PageTitle text={employee.name} />
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant={"body2"} fontSize="small" textTransform="uppercase">
            {t("field-uuid")}
          </Typography>
          <small>{employee.uuid}</small>
        </Box>
        <Box>
          <Typography variant={"body2"} fontSize="small" textTransform="uppercase">
            {t("field-position")}
          </Typography>
          <small>{employee.post}</small>
        </Box>
        <Box>
          <Typography variant={"body2"} fontSize="small" textTransform="uppercase">
            {t("field-email")}
          </Typography>
          <Stack direction="row">
            {employee.emailVerified && <Check color="success" fontSize="small" sx={{ marginTop: '-3px' }} />}
            <small>{employee.email}</small>
          </Stack>
        </Box>
        <Box>
          <Typography variant={"body2"} fontSize="small" textTransform="uppercase">
            {t("field-verification")}
          </Typography>
          <small>{t(employee.clientVerified ? "field-verification-yes" : "field-verification-no")}</small>
        </Box>
      </Stack>
      <Grid container mt={3} columnSpacing={2}>
        {employee.email === currentUser.EMAIL && <Grid item xs={8} sx={{ flexGrow: 1 }}>
          <ValidationContext.Provider value={{ validateOn }}>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'baseline' }}>
              <PasswordControl labelKey="settings.signCode" value={signCode} onChange={(val) => setSignCode(val)}
                            validators={[NotEmpty, StrongPassword, Length(255)]} hintKey="employeeDetails.field.signCode.hint" />
              <LoadingButton loading={isUpdatingSignCode} variant="outlined" color="primary" onClick={() => updateSignCode()} sx={{ height: '41px'}}>
                {t('button.update')}
              </LoadingButton>
            </Stack>
          </ValidationContext.Provider>
        </Grid>}
        <Grid item xs={8} sx={{ flexGrow: 1, mt: 2 }}>
          <Button variant="outlined" color="primary" onClick={() => navigate('/users')}>
            {t("button.back")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}