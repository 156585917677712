import { EntityRoutes } from '../models/route/entity.routes';
import { EditableEntityDto } from '../models/dto/common';
import { BaseEntity, UuidEntity } from '../models/interfaces/common';
import { DocumentDetails, ListRequestDto, ListResponseDto } from '../models/interfaces';
import { HttpClient } from './http.client';

export abstract class CrudService {
  protected readonly baseUrl: string;
  protected readonly routes: EntityRoutes;

  protected constructor(baseUrl: string, routes: EntityRoutes) {
    this.baseUrl = baseUrl;
    this.routes = routes;
  }

  async details(guid: string) {
    return (
      await HttpClient.post<DocumentDetails>(`${this.baseUrl}/details/${guid}`)
    ).data;
  }

  async forUpdate<T extends BaseEntity>(identifier: string) {
    return (await HttpClient.post<T>(`${this.baseUrl}/forUpdate/${identifier}`)).data;
  }

  protected async create<TEntity extends BaseEntity, TResult extends UuidEntity>(dto: EditableEntityDto<TEntity>, urlModifier = '') {
    return (await HttpClient.post<TResult>(`${this.baseUrl}/create${urlModifier}`, dto)).data;
  }

  protected async update<TEntity extends BaseEntity, TResult extends UuidEntity>(identifier: string, dto: EditableEntityDto<TEntity>, urlModifier = '') {
    return (await HttpClient.patch<TResult>(`${this.baseUrl}/modify${urlModifier}/${identifier}`, dto)).data;
  }

  async save<TEntity extends BaseEntity, TResult extends UuidEntity>(dto: EditableEntityDto<TEntity>, urlModifier?: string) {
    try {
      return await (dto.uuid
        ? this.update<TEntity, TResult>(dto.uuid, dto, urlModifier)
        : this.create<TEntity, TResult>(dto, urlModifier));
    } catch (e: any) {
      throw e.response ?? e;
    }
  }

  remove(uuid: string | string[]) {
    return HttpClient.delete(`${this.baseUrl}`, { data: { uuid } });
  }

  async list<T extends BaseEntity>(dto: ListRequestDto<T>) {
    const cleanFilter = CrudService.cleanFilter(dto.filter);
    return (await HttpClient.post<ListResponseDto<T>>(`${this.baseUrl}/list`, { ...dto, filter: cleanFilter })).data;
  }

  listFn = <T extends BaseEntity>(dto: ListRequestDto<T>) => this.list(dto);

  private static cleanFilter<T extends Record<string, unknown>>(filter: T | undefined) {
    if (!filter) return undefined;

    const cleanFilter = {} as T;
    for (const key in filter as T) {
      if (!key.endsWith('View')) {
        cleanFilter[key] = CrudService.cleanFilterValue(filter[key]);
      }
    }

    return Object.values(cleanFilter).some((val) => val !== undefined) ? cleanFilter : undefined;
  }

  private static cleanFilterValue(value: any) {
    switch (typeof value) {
      case 'string':
        return value !== '' && value !== 'null' ? value : undefined;
      case 'object':
        return Array.isArray(value) ? value : CrudService.cleanFilter(value);
      default:
        return value;
    }
  }
}
