import { Rest } from "../rest";
import ValidationError from "../service/fieldValidator/validationError";

export default class Contact {
    constructor(fields) {
        Object.assign(this, fields);
    }

    get Id() {
        return parseInt(this.ID) || 0;
    }

    get Link() {
        return `https://${Rest.getDomain()}/crm/contact/details/${this.Id}/`;
    }

    get FullName() {
        if (this.NAME && this.NAME.length > 0) {
            if (this.SECOND_NAME && this.SECOND_NAME.length > 0) {
                if (this.LAST_NAME && this.LAST_NAME.length > 0) {
                    return this.NAME + ' ' + this.SECOND_NAME + ' ' + this.LAST_NAME;
                }
                return this.NAME + ' ' + this.SECOND_NAME;
            }
            if (this.LAST_NAME && this.LAST_NAME.length > 0) {
                return this.NAME + ' ' + this.LAST_NAME;
            }
            return this.NAME;
        }

        if (this.EMAIL && this.EMAIL.length > 0)
            return this.EMAIL;

        return 'ID: ' + this.Id;
    }

    validateAsSigner() {
        const _ = this;
        const errors = [];

        if (_.HAS_EMAIL !== 'Y') {//!_.EMAIL || _.EMAIL.length <= 0
            errors.push(new ValidationError('field-email', { field: 'Y' }));
        }

        if (!_.LAST_NAME || _.LAST_NAME.length <= 0) {
            errors.push(new ValidationError('field-last-name', { field: 'Y' }));
        }

        if (!_.NAME || _.NAME.length <= 0) {
            errors.push(new ValidationError('field-first-name', { field: 'Y' }));
        }

        if (!_.SECOND_NAME || _.SECOND_NAME.length <= 0) {
            errors.push(new ValidationError('field-second-name', { field: 'Y', warning: 'Y' }));
        }

        if (_.HAS_PHONE !== 'Y') {//!_.PERSONAL_MOBILE || _.PERSONAL_MOBILE.length <= 0
            errors.push(new ValidationError('field-mobile-phone', { field: 'Y' }));
        }
        return errors;
    }
}