export const OIDS = [
  { oid: '1.2.643.3.131.1.1',    short: 'INN',    full: 'ИНН' },
  { oid: '1.2.643.100.4',        short: 'INNLE',  full: 'ИНН ЮЛ' },
  { oid: '1.2.643.100.1',        short: 'OGRN',   full: 'ОГРН' },
  { oid: '1.2.643.100.5',        short: 'OGRNIP', full: 'ОГРНИП' },
  { oid: '1.2.643.100.3',        short: 'SNILS',  full: 'СНИЛС' },
  { oid: '1.2.840.113549.1.9.1', short: 'E',      full: 'emailAddress' },
  { oid: '2.5.4.3',              short: 'CN',     full: 'commonName' },
  { oid: '2.5.4.4',              short: 'SN',     full: 'surname' },
  { oid: '2.5.4.42',             short: 'G',      full: 'givenName' },
  { oid: '2.5.4.6',              short: 'C',      full: 'countryName' },
  { oid: '2.5.4.7',              short: 'L',      full: 'localityName' },
  { oid: '2.5.4.8',              short: 'S',      full: 'stateOrProvinceName' },
  { oid: '2.5.4.9',              short: 'STREET', full: 'streetAddress' },
  { oid: '2.5.4.10',             short: 'O',      full: 'organizationName' },
  { oid: '2.5.4.11',             short: 'OU',     full: 'organizationalUnitName' },
  { oid: '2.5.4.12',             short: 'T',      full: 'title' },
];

export const CAPICOM_PROPID_KEY_PROV_INFO = 2;

export const CADESCOM_CADES_BES = 1;            	// Тип подписи CAdES-BES.
export const CADESCOM_CADES_T = 0x5;              // Тип подписи CAdES-T.
export const CADESCOM_CADES_X_LONG_TYPE_1 = 0x5d; // Тип подписи CAdES-X Long Type 1.

export const TSA = [
  'http://pki.tax.gov.ru/tsp/tsp.srf',                  // (TSP-сервер ФНС)
  'http://tax4.tensor.ru/tsp-tensor_gost2012/tsp.srf',  // (Тензор)
  'http://qs.cryptopro.ru/tsp/tsp.srf',                 // (Такском)
];

export const TSP_ERRORS: Record<string, string> = {
  '0xC2100100':	'При попытке отправки запроса возникла ошибка HTTP',
  '0xC2100101':	'Указанный тип аутентификации запрещен групповой политикой',
  '0xC2100102':	'Указанный тип аутентификации прокси-сервера запрещен групповой политикой',
  '0xC2100103':	'Указанная служба штампов запрещена групповой политикой',
  '0xC2100104':	'Использование поля Nonce запрещено групповой политикой',
  '0xC2100110':	'Указанный алгоритм хэширования запрещен групповой политикой',
  '0xC2100111':	'Указанный "PolicyID" запрещен групповой политикой',
  '0xC2100120':	'Значение полей "Nonce" запроса и штампа не совпадают',
  '0xC2100121':	'Не задан адрес службы штампов времени',
  '0xC2100122':	'Штамп времени просрочен (выдан слишком давно)',
  '0xC2100123':	'В запросе отсутствует хэш-значение',
  '0xC2100124':	'Получен ответ службы штампов времени с ошибкой',
  '0xC2100140':	'Лицензия на КриптоПро TSP Client истекла или не была введена',
}

export const OCSP_ERRORS: Record<string, string> = {
  '0xC2110100':	'При попытке отправки запроса возникла ошибка HTTP',
  '0xC2110101':	'Указанный тип аутентификации запрещен групповой политикой',
  '0xC2110102':	'Указанный тип аутентификации прокси-сервера запрещен групповой политикой',
  '0xC2110103':	'Указанная служба OCSP запрещена групповой политикой',
  '0xC2110104':	'Встречено расширение (AcceptableTypes или Nonce), запрещенное групповой политикой',
  '0xC2110110':	'Подписанные OCSP-запросы запрещены политикой',
  '0xC2110111':	'Неподписанные OCSP-запросы запрещены политикой',
  '0xC2110120':	'Поля "Nonce" OCSP-запроса и ответа не совпадают',
  '0xC2110121':	'Не задан адрес службы OCSP',
  '0xC2110122':	'OCSP-ответ просрочен по значению поля "ProducedAt" или "NextUpdate"',
  '0xC2110123':	'Значение поля "ThisUpdate" OCSP-ответа просрочено',
  '0xC2110124':	'Значение поля "NextUpdate" OCSP-ответа меньше значения "ThisUpdate"',
  '0xC2110125':	'В OCSP-ответе не найден запрашиваемый статус сертификата',
  '0xC2110126':	'Сертификат отозван',
  '0xC2110127':	'Статус сертификата не известен',
  '0xC2110128':	'Получен OCSP-ответ с ошибкой',
  '0xC2110129':	'Полученный OCSP-ответ содержит неизвестное критическое расширение',
  '0xC2110130':	'Время Службы OCSP рассинхронизировано со Службой штампов времени',
  '0xC2110140':	'Лицензия на КриптоПро OCSP Client (Revocation Provider) истекла или не была введена',
}