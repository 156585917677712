import moment from "moment";
import { FileType } from "../enum/fileType";
import Utils from "../utils";
import ApiSignature from "./apiSignature";

export default class Document {
    constructor(fields) {
        Object.assign(this, fields);
        this.originalFileObject = false;
        this.signedFileObject = false;
        this.reportFileObject = false;
        this.agreementFileObject = false;
    }

    get Id() {
        return parseInt(this.ID) || 0;
    }

    get Status() {
        return this.PROPERTY_VALUES.STATUS;
    }

    get StatusComment() {
        return this.PROPERTY_VALUES.STATUS_COMMENT;
    }

    get ResponsibleId() {
        return parseInt(this.PROPERTY_VALUES.RESPONSIBLE) || 0;
    }

    get DealId() {
        return parseInt(this.PROPERTY_VALUES.DEAL) || 0;
    }

    get SignerId() {
        return parseInt(this.PROPERTY_VALUES.SIGNER) || 0;
    }

    get SignerCounterpartyId() {
        return parseInt(this.PROPERTY_VALUES.SIGNER_COUNTERPARTY) || 0;
    }

    get Signatures() {
        return (Utils.tryParseJson(this.PROPERTY_VALUES.SIGNATURES) || []).map(x => new ApiSignature(x.order, x.posX, x.posY, x.page, x.documentWidth, x.documentHeight));
    }

    get OriginalFile() {
        if (this.OriginalFileType === FileType.deal) {
            if (this.originalFileObject) {
                return {
                    link: this.originalFileObject.pdfUrl,
                    name: this.originalFileObject.title
                }
            }
            return false;
        }
        if (this.PROPERTY_VALUES.ORIGINAL_FILE) {
            return {
                link: this.PROPERTY_VALUES.ORIGINAL_FILE,
                name: this.PROPERTY_VALUES.ORIGINAL_FILE_NAME && this.PROPERTY_VALUES.ORIGINAL_FILE_NAME.length > 0 ? this.PROPERTY_VALUES.ORIGINAL_FILE_NAME : '-'
            }
        }
        return false;
    }

    get OriginalFileType() {
        if (this.PROPERTY_VALUES.ORIGINAL_FILE_TYPE === FileType.deal) {
            return FileType.deal;
        }
        return FileType.local;
    }

    get OriginalFileId() {
        if (this.OriginalFileType === FileType.deal) {
            return parseInt(this.PROPERTY_VALUES.ORIGINAL_FILE_ID) || 0;
        }
        return 0;
    }

    get SignedFile() {
        if (this.SignedFileType === FileType.deal) {
            if (this.signedFileObject) {
                return {
                    link: this.signedFileObject.pdfUrl,
                    name: this.signedFileObject.title
                }
            }
            return false;
        }
        if (this.PROPERTY_VALUES.SIGNED_FILE) {
            return {
                link: this.PROPERTY_VALUES.SIGNED_FILE,
                name: this.PROPERTY_VALUES.SIGNED_FILE_NAME && this.PROPERTY_VALUES.SIGNED_FILE_NAME.length > 0 ? this.PROPERTY_VALUES.SIGNED_FILE_NAME : '-'
            };
        }
        return false;
    }

    get SignedFileType() {
        if (this.PROPERTY_VALUES.SIGNED_FILE_TYPE === FileType.deal) {
            return FileType.deal;
        }
        return FileType.local;
    }

    get SignedFileId() {
        if (this.SignedFileType === FileType.deal) {
            return parseInt(this.PROPERTY_VALUES.SIGNED_FILE_ID) || 0;
        }
        return 0;
    }

    get ReportFile() {
        if (this.ReportFileType === FileType.deal) {
            if (this.reportFileObject) {
                return {
                    link: this.reportFileObject.pdfUrl,
                    name: this.reportFileObject.title
                }
            }
            return false;
        }
        if (this.PROPERTY_VALUES.REPORT_FILE) {
            return {
                link: this.PROPERTY_VALUES.REPORT_FILE,
                name: this.PROPERTY_VALUES.REPORT_FILE_NAME && this.PROPERTY_VALUES.REPORT_FILE_NAME.length > 0 ? this.PROPERTY_VALUES.REPORT_FILE_NAME : '-'
            };
        }
        return false;
    }

    get ReportFileType() {
        if (this.PROPERTY_VALUES.REPORT_FILE_TYPE === FileType.deal) {
            return FileType.deal;
        }
        return FileType.local;
    }

    get ReportFileId() {
        if (this.ReportFileType === FileType.deal) {
            return parseInt(this.PROPERTY_VALUES.REPORT_FILE_ID) || 0;
        }
        return 0;
    }

    get AgreementFile() {
        if (this.AgreementFileType === FileType.deal) {
            if (this.agreementFileObject) {
                return {
                    link: this.agreementFileObject.pdfUrl,
                    name: this.agreementFileObject.title
                }
            }
            return false;
        }
        if (this.PROPERTY_VALUES.AGREEMENT_FILE) {
            return {
                link: this.PROPERTY_VALUES.AGREEMENT_FILE,
                name: this.PROPERTY_VALUES.AGREEMENT_FILE_NAME && this.PROPERTY_VALUES.AGREEMENT_FILE_NAME.length > 0 ? this.PROPERTY_VALUES.AGREEMENT_FILE_NAME : '-'
            };
        }
        return false;
    }

    get AgreementFileType() {
        if (this.PROPERTY_VALUES.AGREEMENT_FILE_TYPE === FileType.deal) {
            return FileType.deal;
        }
        return FileType.local;
    }

    get AgreementFileId() {
        if (this.AgreementFileType === FileType.deal) {
            return parseInt(this.PROPERTY_VALUES.AGREEMENT_FILE_ID) || 0;
        }
        return 0;
    }

    get SignDateValue() {
        return parseInt(this.PROPERTY_VALUES.SIGN_DATE) || 0;
    }

    setOriginalFileObject(file) {
        this.originalFileObject = file;
    }

    setSignedFileObject(file) {
        this.signedFileObject = file;
    }

    setReportFileObject(file) {
        this.reportFileObject = file;
    }

    setAgreementFileObject(file) {
        this.agreementFileObject = file;
    }

    get AdditionalAgreementDocumentNumber() {
        return this.PROPERTY_VALUES.ADDITIONAL_AGREEMENT_DOCUMENT_NUMBER && this.PROPERTY_VALUES.ADDITIONAL_AGREEMENT_DOCUMENT_NUMBER.length > 0 ? this.PROPERTY_VALUES.ADDITIONAL_AGREEMENT_DOCUMENT_NUMBER : '';
    }

    get AdditionalAgreementDocumentDate() {
        return this.PROPERTY_VALUES.ADDITIONAL_AGREEMENT_DOCUMENT_DATE && this.PROPERTY_VALUES.ADDITIONAL_AGREEMENT_DOCUMENT_DATE.length > 0 ? new Date(this.PROPERTY_VALUES.ADDITIONAL_AGREEMENT_DOCUMENT_DATE) : new Date();
    }

    /*
    static get AdditionalAgreementDocumentDateFormat() {
        return 'MM.DD.YYYY';
    }
    */
}