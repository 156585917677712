import React from 'react';
import Dropzone from 'react-dropzone'
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import Select from 'react-select';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Loader } from "../component/loader";
import ContactSelect from '../component/select/contact';
import DealSelect from '../component/select/deal';
import UserSelect from '../component/select/user';
import SignPosition from '../component/signPosition';
import FieldErrors from '../component/fieldError';
import PageTitle from '../component/pageTitle';
import ButtonLoader from '../component/buttonLoader';
import { AppContext } from '../context/app.context';
import { DataStorage } from '../enum/dataStorage';
import { DocumentStatus } from '../enum/documentStatus';
import { Rest } from '../rest';
import Utils from '../utils';
import Deal from '../models/deal';
import { LogType } from '../enum/logType';
import { ObjectType } from '../enum/objectType';
import LogService from '../service/logService';
import { FileType } from '../enum/fileType';
import Log from '../models/log';
import { AccessKey } from '../enum/accessKey';
import ValidationError from '../service/fieldValidator/validationError';
import FieldValidator from '../service/fieldValidator/fieldValidator';
import { Checkbox } from '@mui/material';
import { DatePicker } from '@mui/lab';

export default class DocumentPage extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super();
        this.state = {
            isLoaded: false,
            loading: true,
            id: parseInt(props.match?.params.id) || 0,
            status: DocumentStatus.draft,
            responsible: null,
            deal: null,
            signer: null,
            signerCounterparty: null,
            errors: {},
            fileSelected: false,
            fileType: false,
            originalFile: false,
            additionalAgreementDocument: false,
            additionalAgreementDocumentNumber: '',
            additionalAgreementDocumentDate: new Date(),
            signatures: [],
            loadError: false,
            fileInputKey: Date.now(),
            storyKey: Date.now(),
            sendInProcess: 'N',
            accessToResponsibleChange: false,
            accessToSignerChange: false
        };
    }

    componentDidMount() {
        const _ = this;
        this.checkPlacement(function () {
            _.refreshData();
        });
    }

    async checkPlacement(callback) {
        const info = Rest.placementInfo();
        if (this.state.id > 0 || info.placement !== 'CRM_DEAL_DETAIL_TAB' || !info.options.ID) {
            callback();
            return;
        }
        try {
            const deals = await Rest.callMethod('crm.deal.list', { filter: { ID: info.options.ID } });
            if (deals.items.length < 1) {
                callback();
            }
            else {
                const deal = new Deal(deals.items[0]);
                this.setState({
                    deal: {
                        value: deal.Id,
                        label: deal.Name
                    }
                }, () => {
                    callback();
                });
                this.tryFillResponsible(deal.ResponsibleId);
                this.tryFillContact(deal.ContactId);
            }
        }
        catch (err) {
            console.error('checkPlacement crm.deal.list', err);
            callback();
        }
    }

    refreshData = async () => {
        const _ = this;
        if (_.state.id < 1) {
            _.setState({
                loading: false
            }, () => {
                _.refreshDataEnd();
            });
            return;
        }

        _.setState({
            loading: true
        });

        const documents = await Rest.getDocuments([_.state.id]);
        const entity = documents[_.state.id];
        if (!entity) {
            _.setState({
                loading: false,
                loadError: true
            }, () => {
                _.refreshDataEnd();
            });
            return;
        }
        const files = await Rest.getDealFiles([entity.OriginalFileId, entity.SignedFileId, entity.ReportFileId, entity.AgreementFileId]);

        if (files[entity.OriginalFileId]) {
            entity.setOriginalFileObject(files[entity.OriginalFileId]);
        }
        if (files[entity.SignedFileId]) {
            entity.setSignedFileObject(files[entity.SignedFileId]);
        }
        if (files[entity.ReportFileId]) {
            entity.setReportFileObject(files[entity.ReportFileId]);
        }
        if (files[entity.AgreementFileId]) {
            entity.setAgreementFileObject(files[entity.AgreementFileId]);
        }
        const users = await Rest.getUsers([entity.ResponsibleId, entity.SignerId, entity.SignerCounterpartyId]);
        const deals = await Rest.getDeals([entity.DealId]);
        const contacts = await Rest.getContacts([entity.SignerCounterpartyId]);

        const additionalAgreementDocumentNumber = entity.AdditionalAgreementDocumentNumber;
        const additionalAgreementDocumentDate = entity.AdditionalAgreementDocumentDate;
        _.setState({
            status: entity.Status,
            responsible: entity.ResponsibleId > 0 && users[entity.ResponsibleId] ? { value: entity.ResponsibleId, label: users[entity.ResponsibleId].FullName } : null,
            deal: entity.DealId > 0 && deals[entity.DealId] ? { value: entity.DealId, label: deals[entity.DealId].Name } : false,
            signer: entity.SignerId > 0 && users[entity.SignerId] ? { value: entity.SignerId, label: users[entity.SignerId].FullName } : null,
            signerCounterparty: entity.SignerCounterpartyId > 0 && contacts[entity.SignerCounterpartyId] ? { value: entity.SignerCounterpartyId, label: contacts[entity.SignerCounterpartyId].FullName } : null,
            signatures: entity.Signatures,
            originalFile: entity.OriginalFile,
            signedFile: entity.SignedFile,
            reportFile: entity.ReportFile,
            agreementFile: entity.AgreementFile,
            additionalAgreementDocument: additionalAgreementDocumentNumber.length > 0 && additionalAgreementDocumentDate,
            additionalAgreementDocumentNumber: additionalAgreementDocumentNumber,
            additionalAgreementDocumentDate: additionalAgreementDocumentDate,
            fileSelected: false,
            fileType: FileType.local,
            loading: false,
            sendInProcess: 'N'
        }, () => {
            _.refreshDataEnd();
        });
    }

    async refreshDataEnd() {
        const currentUser = this.context.getCurrentUser();
        const userSignData = await Rest.getUserSignData(currentUser.Id);
        const accessSettings = this.context.access(AccessKey.settings);
        var newState = {
            accessToResponsibleChange: accessSettings || userSignData,
            accessToSignerChange: accessSettings || !userSignData,
            isLoaded: true
        };

        if (!this.isReadonly()) {
            if (!newState.accessToResponsibleChange && !this.state.responsible) {
                this.onResposibleChange({ value: currentUser.Id, label: currentUser.FullName });
            }
            if (!newState.accessToSignerChange && !this.state.signer) {
                this.onSignerChange({ value: currentUser.Id, label: currentUser.FullName });
            }
        }

        this.setState(newState, () => {
            Rest.resizeFrame();
        });
    }

    onResposibleChange = async (selected) => {
        const _ = this;
        _.setState({
            responsible: selected
        }, async () => {
            await _.validateResponsible();
            await _.validateSigner();
        });
    }

    onDealChange = (selected) => {
        this.setState({
            deal: selected
        });


        if (this.state.fileType === FileType.deal) {
            this.setState({
                fileSelected: false,
                fileType: false
            });
        }

        if (selected && selected.object) {
            this.tryFillResponsible(selected.object.ResponsibleId);
            this.tryFillContact(selected.object.ContactId);
        }
    }

    onSignerChange = (selected) => {
        const _ = this;
        _.setState({
            signer: selected
        }, () => {
            _.validateSigner();
        });
    }

    async tryFillResponsible(id) {
        if (id < 1) {
            return;
        }
        if (this.state.responsible) {
            return;
        }
        if (this.isReadonly()) {
            return;
        }
        const entities = await Rest.getUsers([id]);
        if (entities[id]) {
            this.onResposibleChange({ value: entities[id].Id, label: entities[id].FullName });
        }
    }

    async tryFillContact(id) {
        if (id < 1) {
            return;
        }
        if (this.state.signerCounterparty) {
            return;
        }
        if (this.isReadonly()) {
            return;
        }
        const entities = await Rest.getContacts([id]);
        if (entities[id]) {
            this.onSignerCounterpartyChange({ value: entities[id].Id, label: entities[id].FullName });
        }
    }

    async validateResponsible() {
        const errors = this.state.errors;
        if (!this.state.responsible) {
            errors['responsible'] = [];
            this.setState({
                errors: errors
            }, () => {
                Rest.resizeFrame();
            });
        }
    }

    async validateSigner() {
        if (!this.state.signer) {
            const errors = this.state.errors;
            errors['signer'] = [];
            if (errors['responsible'] && errors['responsible'].some(x => x.message === 'msg-error-access-to-signer-denied')) {
                errors['responsible'] = [];
            }
            this.setState({
                errors: errors
            });
            return;
        }

        const entities = await Rest.getUsers([this.state.signer.value]);
        const entity = entities[this.state.signer.value];

        if (!entity) {
            return;
        }

        try {
            const errors = this.state.errors;
            const userSignData = await Rest.getUserSignData(entity.Id);
            errors['responsible'] = [];
            errors['signer'] = entity.validateAsSigner(userSignData);

            if (userSignData) {
                if (this.state.responsible?.value && !userSignData.AllResponsibleId.includes(this.state.responsible.value)) {//&& !this.context.access(AccessKey.settings)
                    errors['responsible'].push(new ValidationError('msg-error-access-to-signer-denied'));
                }
            }

            this.setState({
                errors: errors
            }, () => {
                Rest.resizeFrame();
            });
        }
        catch (err) {
            console.error('validateSigner', err);
        }
    }

    onSignerCounterpartyChange = (selected) => {
        const _ = this;
        _.setState({
            signerCounterparty: selected
        }, () => {
            _.validateSignerCounterparty();
        });
    }

    onAdditionalAgreementDocumentChange = (e) => {
        const _ = this;
        _.setState({
            additionalAgreementDocument: e.target.checked
        }, () => {
            Rest.resizeFrame();
        });
    }

    onAdditionalAgreementDocumentNumberChange = (e) => {
        const _ = this;
        _.setState({
            additionalAgreementDocumentNumber: e.target.value
        }, () => {
            _.validateAdditionalAgreementDocumentNumberChange();
        });
    }

    validateAdditionalAgreementDocumentNumberChange() {
        const _ = this;
        if (!_.state.errors['additionalAgreementDocumentNumber'] || _.state.errors['additionalAgreementDocumentNumber'].length < 1)
            return;
        const errors = _.state.errors;
        errors['additionalAgreementDocumentNumber'] = [];
        _.setState({
            errors: errors
        });
    }

    onAdditionalAgreementDocumentDateChange = (e) => {
        const _ = this;
        _.setState({
            additionalAgreementDocumentDate: e.target.value && e.target.value.length > 0 ? new Date(e.target.value) : new Date()
        }, () => {
            _.validateAdditionalAgreementDocumentDateChange();
        });
    }

    validateAdditionalAgreementDocumentDateChange() {
        const _ = this;
        if (!_.state.errors['additionalAgreementDocumentDate'] || _.state.errors['additionalAgreementDocumentDate'].length < 1)
            return;
        const errors = _.state.errors;
        errors['additionalAgreementDocumentDate'] = [];
        _.setState({
            errors: errors
        });
    }

    async validateSignerCounterparty() {
        if (!this.state.signerCounterparty) {
            const errors = this.state.errors;
            errors['signerCounterparty'] = [];
            this.setState({
                errors: errors
            });
            return;
        }

        const entities = await Rest.getContacts([this.state.signerCounterparty.value]);

        if (entities[this.state.signerCounterparty.value]) {
            const errors = this.state.errors;
            errors['signerCounterparty'] = entities[this.state.signerCounterparty.value].validateAsSigner();
            this.setState({
                errors: errors
            }, () => {
                Rest.resizeFrame();
            });
        }
    }

    clickRemoveOriginalFile = () => {
        this.setState({
            originalFile: false,
            signatures: []
        });
    }

    onSelectDealDocument = async (item) => {
        this.setState({
            fileSelected: item,
            fileType: FileType.deal,
            signatures: [],
            fileInputKey: Date.now()
        });
    }

    onFileRead = async (files) => {
        const file = files[0];
        //validate size limit?
        //size: 1
        //type: "application/pdf"
        const errors = this.state.errors;
        errors['file'] = [];
        if (!file) {
            this.setState({
                errors: errors,
                fileSelected: false
            }, () => {
                Rest.resizeFrame();
            });
            return;
        }
        if (!file || file.type !== 'application/pdf') {
            errors['file'].push(new ValidationError('msg-invalid-file-type'));
            this.setState({
                errors: errors,
                fileSelected: false
            }, () => {
                Rest.resizeFrame();
            });
            return;
        }
        this.setState({
            errors: errors,
            fileSelected: file,
            fileType: FileType.local,
            signatures: []
        }, () => {
            Rest.resizeFrame();
        });
    }

    async getRequestData(file) {
        const result = {
            ENTITY: DataStorage.document,
            ID: this.state.id > 0 ? this.state.id : '',
            NAME: 'document',
            PROPERTY_VALUES: {
                RESPONSIBLE: this.state.responsible ? this.state.responsible.value : '',
                DEAL: this.state.deal ? this.state.deal.value : '',
                SIGNER: this.state.signer ? this.state.signer.value : '',
                SIGNER_COUNTERPARTY: this.state.signerCounterparty ? this.state.signerCounterparty.value : '',
                SIGNATURES: JSON.stringify(this.state.signatures),
                ADDITIONAL_AGREEMENT_DOCUMENT_NUMBER: this.state.additionalAgreementDocument ? this.state.additionalAgreementDocumentNumber : '',
                ADDITIONAL_AGREEMENT_DOCUMENT_DATE: this.state.additionalAgreementDocument ? this.state.additionalAgreementDocumentDate.toISOString().slice(0, 10) : ''
            }
        };
        if (file) {
            if (this.state.fileType === FileType.local) {
                result.PROPERTY_VALUES.ORIGINAL_FILE_TYPE = FileType.local;
                result.PROPERTY_VALUES.ORIGINAL_FILE_NAME = file.name;
                result.PROPERTY_VALUES.ORIGINAL_FILE = [file.name, await Utils.convertFileBase64(file)];
            }
            if (this.state.fileType === FileType.deal) {
                result.PROPERTY_VALUES.ORIGINAL_FILE_TYPE = FileType.deal;
                result.PROPERTY_VALUES.ORIGINAL_FILE_NAME = file.title;
                result.PROPERTY_VALUES.ORIGINAL_FILE_ID = file.id;
            }
        }
        if (this.state.id < 1) {
            result.PROPERTY_VALUES.STATUS = DocumentStatus.draft;
        }
        return result;
    }

    validateFields() {
        const { errors } = this.state;

        const fields = [
            {
                name: 'signatures', checks: [
                    { check: this.state.signatures.length < 1, error: new ValidationError('field-signatures', { field: 'Y' }) }
                ]
            },
            {
                name: 'responsible', checks: [
                    { check: !this.state.responsible, error: new ValidationError('field-responsible', { field: 'Y' }) }
                ]
            },
            {
                name: 'signer', checks: [
                    { check: !this.state.signer, error: new ValidationError('field-signer', { field: 'Y' }) }
                ]
            },
            {
                name: 'signerCounterparty', checks: [
                    { check: !this.state.signerCounterparty, error: new ValidationError('field-signer-counterparty', { field: 'Y' }) }
                ]
            },
            {
                name: 'additionalAgreementDocumentNumber', checks: [
                    { check: this.state.additionalAgreementDocument && (!this.state.additionalAgreementDocumentNumber || this.state.additionalAgreementDocumentNumber.length < 1), error: new ValidationError('document-additional-agreement-document-number', { field: 'Y' }) }
                ]
            },
            {
                name: 'additionalAgreementDocumentDate', checks: [
                    { check: this.state.additionalAgreementDocument && !this.state.additionalAgreementDocumentDate, error: new ValidationError('document-additional-agreement-document-date', { field: 'Y' }) }//,
                    //{ check: this.state.additionalAgreementDocument && !moment(this.state.additionalAgreementDocumentDate, Document.AdditionalAgreementDocumentDateFormat, true).isValid(), error: new ValidationError('document-additional-agreement-document-date', { format: Document.AdditionalAgreementDocumentDateFormat }) }
                ]
            }
        ];

        const result = FieldValidator.validate(fields, errors);
        if (result.updateState) {
            this.setState({
                errors: errors
            }, () => {
                Rest.resizeFrame();
            });
        }
        return result.isValid;
    }

    saveAction() {
        const _ = this;
        return new Promise(async (resolve, reject) => {
            try {
                if (!_.validateFields()) {
                    resolve(false);
                    return;
                }
                const selectedFile = _.state.fileSelected ? this.state.fileSelected : false;
                const rd = await _.getRequestData(selectedFile);
                const result = await Rest.callMethod(`entity.item.${_.state.id > 0 ? 'update' : 'add'}`, rd);
                const currentUserId = _.context.getCurrentUser().Id;
                if (selectedFile) {
                    Rest.scrollParentWindow();
                }
                if (result.items.length > 0 && _.state.id < 1) {
                    new LogService(LogType.DocumentAdd, currentUserId, result.items[0], ObjectType.document).add();
                    _.setState({
                        id: result.items[0]
                    }, () => {
                        resolve(true);
                    });
                }
                else {
                    new LogService(LogType.DocumentEdit, currentUserId, _.state.id, ObjectType.document).add();
                    resolve(true);
                }
            }
            catch (err) {
                reject(err);
            }
        });
    }

    clickSave = async (e) => {
        if (e) e.preventDefault();

        try {
            const saveResult = await this.saveAction();
            if (!saveResult) {
                return;
            }
            this.refreshData();
        }
        catch (err) {
            console.log('save document err', err);
        }
    }

    clickSend = async (e) => {
        if (e) e.preventDefault();

        const currentUserAuthData = Rest.getAuth();
        if (!currentUserAuthData)
            return;

        try {
            const saveResult = await this.saveAction();
            if (!saveResult) {
                return;
            }
            this.setState({
                sendInProcess: 'Y'
            });
            currentUserAuthData.documentId = this.state.id;
            const result = await axios.post('/document/send', currentUserAuthData);//'/test' '/document/send'
            const data = result.data;
            this.setState({
                sendInProcess: 'done'
            });
            this.refreshData();
        }
        catch (err) {
            console.error('send document err', Utils.getError(err));
            this.setState({
                sendInProcess: 'error'
            });
        }
    }

    clickDelete = async (e) => {
        if (e) e.preventDefault();
        try {
            const result = Rest.callMethod('entity.item.delete', { ENTITY: DataStorage.document, ID: this.state.id });
            new LogService(LogType.DocumentDelete, this.context.getCurrentUser().Id, this.state.id, ObjectType.document).add();
            this.props.history.push('/');
        }
        catch (err) {
            console.error('clickDelete document', err);
        }
    }

    clickUpdate = () => {
        if (this.state.loading) {
            return;
        }
        this.setState({
            storyKey: Date.now()
        });
        this.refreshData();
    }

    setSignatures = (signatures) => {
        const { errors } = this.state;
        errors['signatures'] = [];
        this.setState({
            signatures: signatures,
            errors: errors
        });
    }

    isReadonly() {
        return this.state.status !== DocumentStatus.draft;
    }

    render() {
        if (this.loading && !this.isLoaded) {
            return (
                <div>
                    <DocumentPageTitle id={this.state.id} status={this.state.status} deal={this.state.deal} />
                    <Loader />
                </div>
            );
        }
        if (this.state.loadError) {
            return (
                <div>
                    <DocumentPageTitle id={this.state.id} status={this.state.status} deal={this.state.deal} />
                    <div class="alert alert-danger d-inline-block" role="alert" data-mdb-color="danger">
                        <Trans>msg-document-not-found</Trans>
                    </div>
                </div>
            );
        }
        const isReadonly = this.isReadonly();
        return (
            <div>
                <DocumentPageTitle id={this.state.id} status={this.state.status} deal={this.state.deal} />
                <div className="row">
                    <div className="col-lg-8 col-xl-8 col-xxl-9">
                        <form>
                            {((!isReadonly) || (isReadonly && this.state.deal)) &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        {!isReadonly &&
                                            <div className="form-outline">
                                                <DealSelect value={this.state.deal} onChange={this.onDealChange} title="field-deal" />
                                            </div>
                                        }
                                        {isReadonly && this.state.deal &&
                                            <>
                                                <div><Trans>field-deal</Trans></div>
                                                <div>
                                                    <a href={`https://${Rest.getDomain()}/crm/deal/details/${this.state.deal.value}/`} target="_blank">{this.state.deal.label}</a>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            {!this.state.originalFile &&
                                <div className="row mt-4">
                                    <div className="col-12 col-lg-6 col-xxl-4">
                                        <Dropzone onDrop={this.onFileRead}>
                                            {({ getRootProps, getInputProps, isDragActive }) => (
                                                <div {...getRootProps()} className="file-drop">
                                                    <input {...getInputProps()} accept="application/pdf" />
                                                    <div>
                                                        <i className="fas fa-cloud-upload-alt fa-3x"></i>
                                                    </div>
                                                    <div className="text-center">
                                                        {!isDragActive
                                                            ? <Trans>document-select-file-drag-text</Trans>
                                                            : <Trans>document-select-file-drop-text</Trans>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        <div>
                                            <FieldErrors errors={this.state.errors['file']} />
                                        </div>
                                    </div>
                                    {this.state.deal &&
                                        <DealFileSelect dealId={this.state.deal.value} fileSelected={this.state.fileType === FileType.deal ? this.state.fileSelected : false} onSelectDocument={this.onSelectDealDocument} />
                                    }
                                </div>
                            }
                            {this.state.originalFile &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <a href={this.state.originalFile.link} target="_blank"><i className="fas fa-download me-2"></i><Trans>field-original-file</Trans></a>
                                            </div>
                                            {!isReadonly &&
                                                <div>
                                                    <button className="btn btn-outline-danger px-3" onClick={this.clickRemoveOriginalFile}>
                                                        <i className="fas fa-times"></i>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.signedFile &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        <a href={this.state.signedFile.link} target="_blank"><i className="fas fa-download me-2"></i><Trans>field-signed-file</Trans></a>
                                    </div>
                                </div>
                            }
                            {this.state.agreementFile &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        <a href={this.state.agreementFile.link} target="_blank"><i className="fas fa-download me-2"></i><Trans>field-agreement-file</Trans></a>
                                    </div>
                                </div>
                            }
                            {this.state.reportFile &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        <a href={this.state.reportFile.link} target="_blank"><i className="fas fa-download me-2"></i><Trans>field-report-file</Trans></a>
                                    </div>
                                </div>
                            }
                            {this.state.fileSelected && !isReadonly &&
                                <div className="mt-4">
                                    <SignPosition file={this.state.fileType === FileType.deal ? this.state.fileSelected.pdfUrlMachine : this.state.fileSelected} signatures={this.state.signatures} setSignatures={this.setSignatures} />
                                </div>
                            }
                            {!isReadonly &&
                                <div>
                                    <FieldErrors errors={this.state.errors['signatures']} />
                                </div>
                            }
                            {((!isReadonly) || (isReadonly && this.state.responsible)) &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        {!isReadonly && this.state.accessToResponsibleChange &&
                                            <div className="form-outline">
                                                <UserSelect value={this.state.responsible} onChange={this.onResposibleChange} title="field-sign-responsible" />
                                                <FieldErrors errors={this.state.errors['responsible']} />
                                            </div>
                                        }
                                        {(isReadonly || !this.state.accessToResponsibleChange) && this.state.responsible &&
                                            <>
                                                <div><Trans>field-sign-responsible</Trans></div>
                                                <div>
                                                    <a href={`https://${Rest.getDomain()}/company/personal/user/${this.state.responsible.value}/`} target="_blank">{this.state.responsible.label}</a>
                                                </div>
                                                <FieldErrors errors={this.state.errors['responsible']} />
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            {(!isReadonly || (isReadonly && this.state.signer)) &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        {!isReadonly && this.state.accessToSignerChange &&
                                            <div className="form-outline">
                                                <UserSelect value={this.state.signer} onChange={this.onSignerChange} title="field-signer" />
                                                <FieldErrors errors={this.state.errors['signer']} />
                                            </div>
                                        }
                                        {(isReadonly || !this.state.accessToSignerChange) && this.state.signer &&
                                            <>
                                                <div><Trans>field-signer</Trans></div>
                                                <div>
                                                    <a href={`https://${Rest.getDomain()}/company/personal/user/${this.state.signer.value}/`} target="_blank">{this.state.signer.label}</a>
                                                </div>
                                                <FieldErrors errors={this.state.errors['signer']} />
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            {((!isReadonly) || (isReadonly && this.state.signerCounterparty)) &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        {!isReadonly &&
                                            <>
                                                <div className="d-flex">
                                                    <div className="form-outline flex-grow-1">
                                                        <ContactSelect value={this.state.signerCounterparty} onChange={this.onSignerCounterpartyChange} title="field-signer-counterparty" />
                                                    </div>
                                                    <div>
                                                        <a className="btn btn-outline-success px-3 h-100 ms-1" href={`https://${Rest.getDomain()}/crm/contact/details/0/`} target="_blank">
                                                            <i className="fas fa-plus"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <FieldErrors errors={this.state.errors['signerCounterparty']} />
                                            </>
                                        }
                                        {isReadonly && this.state.signerCounterparty &&
                                            <>
                                                <div><Trans>field-signer-counterparty</Trans></div>
                                                <div>
                                                    <a href={`https://${Rest.getDomain()}/crm/contact/details/${this.state.signerCounterparty.value}/`} target="_blank">{this.state.signerCounterparty.label}</a>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            {((!isReadonly) || (isReadonly && this.state.additionalAgreementDocument)) &&
                                <div className="row mt-4">
                                    {!isReadonly &&
                                        <div className="col-12 col-xl-8">
                                            <div className="mx-1">
                                                <Checkbox id="additionalAgreementDocument" checked={this.state.additionalAgreementDocument} onChange={this.onAdditionalAgreementDocumentChange} label={i18next.t('document-additional-agreement-document')} />
                                            </div>
                                        </div>
                                    }
                                    {!isReadonly && this.state.additionalAgreementDocument &&
                                        <>
                                            <div className="col-12 col-xl-8 mt-4">
                                                <div><Trans>document-additional-agreement-document-description</Trans></div>
                                            </div>
                                            <div className="col-12 col-xl-8 mt-4">
                                                <DatePicker type="text" value={this.state.additionalAgreementDocumentNumber} onChange={this.onAdditionalAgreementDocumentNumberChange} label={i18next.t('document-additional-agreement-document-number')} required />
                                                <FieldErrors errors={this.state.errors['additionalAgreementDocumentNumber']} />
                                            </div>
                                            <div className="col-12 col-xl-8 mt-4">
                                                <DatePicker type="date" max="2100-01-01" onChange={this.onAdditionalAgreementDocumentDateChange} value={`${this.state.additionalAgreementDocumentDate ? this.state.additionalAgreementDocumentDate.toISOString().slice(0, 10) : ''}`} label={i18next.t('document-additional-agreement-document-date')} required />
                                                <FieldErrors errors={this.state.errors['additionalAgreementDocumentDate']} />
                                            </div>
                                        </>
                                    }
                                    {isReadonly && this.state.additionalAgreementDocument &&
                                        <>
                                            <div className=""><Trans>document-additional-agreement-document-number</Trans></div>
                                            <div>{this.state.additionalAgreementDocumentNumber}</div>
                                            <div className="mt-4"><Trans>document-additional-agreement-document-date</Trans></div>
                                            <div>{this.state.additionalAgreementDocumentDate.toLocaleDateString()}</div>
                                        </>
                                    }
                                    <div className="col-12 col-xl-8 mt-4"><a href="https://avisclouddev.blob.core.windows.net/avissigndocuments/AdditionalAgreementAvisSIGN.pdf" target="_blank"></a></div>
                                </div>
                            }
                            {!this.loading &&
                                <div className="mt-4">
                                    {(this.state.status === DocumentStatus.draft || this.state.sendInProcess !== 'N') && this.state.id > 0 &&
                                        <button type="button" className={`btn ${this.state.sendInProcess === 'error' ? 'btn-danger' : 'btn-success'} me-4`} onClick={this.clickSend} disabled={this.state.sendInProcess !== 'N'}>
                                            <Trans>btn-send-sign</Trans>
                                            {this.state.sendInProcess === 'Y' &&
                                                <ButtonLoader />
                                            }
                                            {this.state.sendInProcess === 'done' &&
                                                <i className="fas fa-check ms-2"></i>
                                            }
                                            {this.state.sendInProcess === 'error' &&
                                                <i className="fas fa-exclamation ms-2"></i>
                                            }
                                        </button>
                                    }
                                    {this.state.status === DocumentStatus.draft &&
                                        <button type="button" className="btn btn-primary me-2" onClick={this.clickSave}><Trans>btn-save</Trans></button>
                                    }
                                    {this.state.id > 0 &&
                                        <button type="button" className="btn btn-outline-danger me-2" onClick={this.clickDelete}><Trans>btn-delete</Trans></button>
                                    }
                                    <Link to="/" className="btn btn-outline-primary me-2"><Trans>btn-cancel</Trans></Link>
                                    {this.state.id > 0 && this.state.status !== DocumentStatus.emailComplitedDoc && this.state.status !== DocumentStatus.denied &&
                                        <button type="button" className="btn btn-outline-primary me-2" onClick={this.clickUpdate}>
                                            <Trans>btn-update</Trans>
                                            {this.state.loading &&
                                                <ButtonLoader />
                                            }
                                        </button>
                                    }
                                </div>
                            }
                        </form>
                    </div>
                    <div className="col-lg-4 col-xl-4 col-xxl-3 mt-3 mt-lg-0">
                        {this.state.id > 0 &&
                            <DocumentHistory id={this.state.id} key={this.state.storyKey} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

class DocumentHistory extends React.Component {
    constructor() {
        super();
        this.state = {
            items: []
        };
    }

    componentDidMount() {
        this.refreshData();
    }

    async refreshData() {
        try {
            const requestData = {
                ENTITY: DataStorage.log,
                SORT: {
                    property_date: 'asc'
                },
                filter: {
                    property_object_id: [this.props.id],
                    property_object_type: [ObjectType.document]
                }
            };

            const result = await Rest.callMethod('entity.item.get', requestData, true);
            const items = result.items.map(x => new Log(x));
            const userIds = [];
            items.forEach((item) => {
                if (!userIds.includes(item.PROPERTY_VALUES.USER)) {
                    userIds.push(item.PROPERTY_VALUES.USER);
                }
            });
            const users = await Rest.getUsers(userIds);

            let lastStatus = false;
            items.forEach(item => {
                if (users[item.UserId]) {
                    item.User = users[item.UserId];
                }
                if (lastStatus) {
                    item.lastStatus = lastStatus;
                }
                if (item.Type === LogType.DocumentStatus) {
                    lastStatus = item.Description;
                }
            });

            items.reverse();

            if (items.length > 0) {
                await this.checkCurrentStatus(items[0]);
            }

            this.setState({
                items: items
            }, () => {
                Rest.resizeFrame();
            });
        }
        catch (err) {
            console.error('DocumentHistory', err);
        }
    }

    /**
     * Проверяет последний статус с истории и сверяет с текущим статусом документа. Если последний статус EmailComplitedDoc то обновлеяет текущий статус документа
     * @param {*} logItem 
     * @returns 
     */
    async checkCurrentStatus(logItem) {
        try {
            const lastStatus = logItem.Description;
            if (lastStatus !== DocumentStatus.emailComplitedDoc) {
                return;
            }
            const documents = await Rest.getDocuments([this.props.id]);
            const entity = documents[this.props.id];
            if (entity && entity.Status !== lastStatus) {
                const rd = {
                    ENTITY: DataStorage.document,
                    ID: entity.ID,
                    PROPERTY_VALUES: {
                        SIGN_DATE: logItem.PROPERTY_VALUES.DATE,
                        STATUS: logItem.Description
                    }
                };
                const result = await Rest.callMethod(`entity.item.update`, rd);
            }
        }
        catch (err) {
            console.warn('error checkCurrentStatus', err);
        }
    }

    render() {
        if (this.state.items.length < 1) {
            return (
                <></>
            );
        }
        return (
            <div className="document-history mt-4">
                {this.state.items.map(item => {
                    const description = item.Type === LogType.DocumentStatus ? (
                        <div className="document-history-statuses">
                            {item.lastStatus &&
                                <>
                                    <div className={Utils.getDocumentStatusClass(item.lastStatus)}><small><Trans>document-status-{item.lastStatus}</Trans></small></div>
                                    <div><i className="fas fa-long-arrow-alt-right mx-2 text-black-50"></i></div>
                                </>
                            }
                            <div className={Utils.getDocumentStatusClass(item.Description)}><small><Trans>document-status-{item.Description}</Trans></small></div>
                        </div>
                    ) : (<div>{item.Description}</div>);
                    return (
                        <div key={item.ID} className="mb-2 p-2 border">
                            {item.User &&
                                <div className="float-end">
                                    {item.User.LinkIconView}
                                </div>
                            }
                            <div><span className="fw-bolder me-2"><Trans>log-{item.Type}</Trans></span><span className="text-black-50">{item.Date.format('L LTS')}</span></div>
                            {description}
                        </div>
                    );
                })}
            </div>
        );
    }
}

class DocumentPageTitle extends React.Component {
    render() {
        if (this.props.id < 1) {
            if (this.props.deal) {
                return (
                    <PageTitle text="document-page-title-new-deal" values={{ val: this.props.deal.label }} />
                );
            }
            return (
                <PageTitle text="document-page-title-new" />
            );
        }

        if (this.props.status === DocumentStatus.draft) {
            if (this.props.deal) {
                return (
                    <PageTitle text="document-page-title-edit-deal" values={{ val: this.props.deal.label }} />
                );
            }
            return (
                <PageTitle text="document-page-title-edit" />
            );
        }

        if (this.props.deal) {
            return (
                <PageTitle text="document-page-title-view-deal" values={{ val: this.props.deal.label }} />
            );
        }
        return (
            <PageTitle text="document-page-title-view" />
        );
    }
}

class DealFileSelect extends React.Component {
    constructor() {
        super();
        this.state = {
            dealId: false,
            documents: []
        };
        this.delay = 500;
        this.loadTimestamp = 0;
    }

    componentDidMount() {
        this.refreshData();
    }

    componentDidUpdate() {
        if (this.state.dealId === this.props.dealId) {
            return;
        }
        this.refreshData();
    }

    async refreshData() {
        const _ = this;
        const now = new Date().getTime();
        _.loadTimestamp = now;

        setTimeout(() => {
            if (_.loadTimestamp !== now)
                return;
            _.loadDocuments();
        });
    }

    async loadDocuments() {
        try {
            const list = await Rest.callMethod('crm.documentgenerator.document.list', {
                filter: {
                    entityTypeId: 2,//deals
                    entityId: this.props.dealId
                }
            }, true);

            this.setState({
                documents: list.items.map(x => {
                    return { ...x, value: x.id, label: x.title }
                }),
                dealId: this.props.dealId
            }, () => {
                Rest.resizeFrame();
            });
            console.log('documents list', list);

        }
        catch (err) {
            console.error('loadDocuments', err);
            this.setState({
                documents: [],
                dealId: this.props.dealId
            }, () => {
                Rest.resizeFrame();
            });
        }
    }

    render() {
        if (this.state.documents.length < 1) {
            return (
                <></>
            );
        }
        const selected = this.props.fileSelected ? this.state.documents.find(x => x.id === this.props.fileSelected.id) : null;
        return (
            <div className="col-12 col-lg-6 col-xxl-4">
                <div className="d-flex mt-0 mt-lg-4">
                    <div className="me-3 mt-1"><Trans>document-select-file-or</Trans></div>
                    <div className="flex-grow-1">
                        <div className="form-outline">
                            <Select className="w-100" value={selected} onChange={this.props.onSelectDocument} options={this.state.documents} isClearable={true} placeholder=""></Select>
                            <label className={`select-label ${this.props.fileSelected ? 'active' : ''}`}><Trans>document-select-file-deal</Trans></label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}