import { Box } from '@mui/material';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

export function Footer() {
  const { t } = useTranslation();
  const { data: apiBuild } = useQuery('build', () => process.env.REACT_APP_BRANCH
    ? axios.get<string>(`/buildInfo`)
    : Promise.resolve(null)
  );

  return (
    <Box mt={2} textAlign="right">
      <small style={{ opacity: .5 }}>{t('appVersion', [process.env.REACT_APP_BRANCH ?? 'DEV', apiBuild?.data ?? 'DEV'])}</small>
    </Box>
  )
}