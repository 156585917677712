import React from "react";
import { Trans } from "react-i18next";
import User from "../models/user";
import { Rest } from "../rest";
import { Loader } from "./loader";
import { Box, Button, Grid, Link, Stack } from '@mui/material';
import { HttpClient } from '../service/http.client';

interface IState {
    isLoaded: boolean,
    domainAuth?: User
}

export default class DomainAuthorization extends React.Component<any, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    componentDidMount() {
        this.checkDomainAuth();
    }

    async checkDomainAuth() {
        const currentUserAuthData = Rest.getAuth();
        if (!currentUserAuthData)
            return;

        try {
            const result = await HttpClient.post('/domain/auth');
            const data = result.data;
            this.setState({
                isLoaded: true,
                domainAuth: new User(data.domainAuth) || false
            });
        }
        catch (err) {
            console.error('error checkDomainAuth', err);
            this.setState({
                isLoaded: true
            });
        }
    }

    sendAuthData = async () => {
        const currentUserAuthData = Rest.getAuth();
        if (!currentUserAuthData)
            return;

        currentUserAuthData.lang = Rest.getLang();

        this.setState({
            isLoaded: false
        });

        try {
            const result = await HttpClient.post('/domain/save');
            const data = result.data;
            if (data.result) {
                this.checkDomainAuth();
            }
            else {
                console.error('error sendAuthData', data, result);
                this.setState({
                    isLoaded: true
                });
            }
        }
        catch (err) {
            console.error('catch error sendAuthData', err);
            this.setState({
                isLoaded: true
            });
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <Grid container>
                    <Grid xs={12} xl={6}>
                        <Loader />
                    </Grid>
                </Grid>
            );
        }

        return (
            <Box>
                <Box sx={{ flexGrow: 1 }}>
                    {!this.state.domainAuth
                        ?
                        <Box sx={{ backgroundColor: 'warning.main', p: 1, color: '#fff' }}>
                            <Trans>msg-server-domain-not-authorized</Trans>
                        </Box>
                        :
                        <Stack direction="column" spacing={1}>
                            <small style={{ textTransform: 'uppercase'}}><Trans>msg-server-domain-authorized</Trans>:</small>
                            <Link href={this.state.domainAuth.Link} target="_blank">{this.state.domainAuth.FullName}</Link>
                        </Stack>
                    }
                </Box>
                { Rest.isAdmin() && (
                  <Box sx={{ ms: 0,  mt: 1 }}>
                    <Button variant="contained" color="success" type="button" onClick={() => this.sendAuthData()}>
                        <Trans>settings-send-user-auth-data</Trans>
                    </Button>
                  </Box>
                )}
            </Box>
        );
    }
}