import { TabProps } from "./tab.props";
import { Stack } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { DownloadLink } from "../../component/DownloadLink";
import { useTranslation } from "react-i18next";
import {
  DocumentFileType,
  SignxDocumentStatus,
  SignxSignerStatusEnum,
} from "../../models/enum";
import { DocumentService } from "../../service/document.service";

export function FilesTab({ index, tab, document }: TabProps) {
  const { t } = useTranslation();
  return index === tab ? (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" alignItems="center">
        <DownloadIcon color="primary" />
        <DownloadLink downloader={() => Promise.resolve(document.originalFile)}>
          {t("documentFile.ORIGINAL")}
        </DownloadLink>
      </Stack>
      {document.signers
        ?.filter((item) => item.status === SignxSignerStatusEnum.SIGNED)
        .map((item) => (
          <Stack direction="row" alignItems="center">
            <DownloadIcon color="primary" />
            <DownloadLink
              downloader={() =>
                DocumentService.download(
                  document.guid,
                  DocumentFileType.SIGNATURE,
                  item.employee.uuid
                )
              }
            >
              {t("documentFile.SIGNATURE", [item.employee.name])}
            </DownloadLink>
          </Stack>
        ))}
      {document.status === SignxDocumentStatus.SIGNED && (
        <>
          <Stack direction="row" alignItems="center">
            <DownloadIcon color="primary" />
            <DownloadLink
              downloader={() =>
                DocumentService.download(document.guid, DocumentFileType.REPORT)
              }
            >
              {t("documentFile.REPORT")}
            </DownloadLink>
          </Stack>
          <Stack direction="row" alignItems="center">
            <DownloadIcon color="primary" />
            <DownloadLink
              downloader={() =>
                DocumentService.download(document.guid, DocumentFileType.PRINT)
              }
            >
              {t("documentFile.PRINT")}
            </DownloadLink>
          </Stack>
          <Stack direction="row" alignItems="center">
            <DownloadIcon color="primary" />
            <DownloadLink
              downloader={() =>
                DocumentService.download(
                  document.guid,
                  DocumentFileType.ARCHIVE
                )
              }
            >
              {t("documentFile.ARCHIVE")}
            </DownloadLink>
          </Stack>
        </>
      )}
    </Stack>
  ) : null;
}
