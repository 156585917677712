export const MediaType = {
  TEXT: 'text/plain', // txt
  PNG: 'image/png', // png
  JPG: 'image/jpeg', // jpeg, jpg
  SVG: 'image/svg+xml', // svg
  TIFF: 'image/tiff', // tiff, tif
  PDF: 'application/pdf', // pdf
  DOC: 'application/msword', // doc
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  PAGES: 'application/vnd.apple.pages', // pages
  XLS: 'application/vnd.ms-excel', // xls
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  NUMBERS: 'application/vnd.apple.numbers', // numbers
  RTF: 'application/rtf', // rtf
  ODT: 'application/vnd.oasis.opendocument.text', // odt
  WPD: 'application/wordperfect', // wpd, wp
  HTML: 'text/html', // html, htm
  XHTML: 'application/xhtml+xml', // xhtml
  XML: 'application/xml', //xml
  XML_OBSOLETE: 'text/xml', //xml
  ZIP: 'application/zip', //zip
  PEM: 'application/x-pem-file', // certificate, signature
};
