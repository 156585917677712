import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Rest } from "../rest";
import Utils from "../utils";

class CHttpClient {
  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return axios.post(
      url,
      {
        ...Rest.getAuth(),
        client: Utils.getFromLocalStorage("client"),
        ...data,
      },
      config
    );
  }

  patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
    return axios.patch(
      url,
      {
        ...data,
        ...Rest.getAuth(),
        client: Utils.getFromLocalStorage("client"),
      },
      config
    );
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    config = config ?? {};
    config.data = {
      ...config.data,
      ...Rest.getAuth(),
      client: Utils.getFromLocalStorage("client"),
    }
    return axios.delete(url, config);
  }
}

export const HttpClient = new CHttpClient();
