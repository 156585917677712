import { GridOverlay } from '@mui/x-data-grid';
import React from 'react';
import { Loader } from "../loader";

export function TableLoader() {
  return (
    <GridOverlay sx={{ background: '#fff' }}>
      <Loader size={80} />
    </GridOverlay>
  );
}
