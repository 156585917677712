import {
  GridAlignment,
  GridCellClassNamePropType,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams, GridRowModel,
  GridRowSelectionModel,
  GridValidRowModel,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { Box, Link } from '@mui/material';
import { LinkProps, useNavigate } from 'react-router-dom';
import { ConfirmOptions } from 'material-ui-confirm';
import { SelectItem } from '../../models/interfaces';
import { ReactNode } from 'react';

export class TableSettings {
  onPage: number;
  visibleColumns: string[];
  sortBy: string;
  sortOrder: 'asc' | 'desc';

  constructor(json?: any, onPage?: number, sortBy?: string, sortOrder?: string) {
    this.onPage = json?.onPage ?? onPage ?? 10;
    this.visibleColumns = Array.isArray(json?.visibleColumns) ? json.visibleColumns : [];
    this.sortBy = json?.sortBy ?? sortBy ?? '';
    this.sortOrder = json?.sortOrder ?? sortOrder ?? 'asc';
  }
}

export type ITableColumn<T extends GridValidRowModel> = GridColDef<T> & {
  headerName: string;
};

export class TableColumn<T extends GridValidRowModel> {
  field: string;
  headerName: string;
  sortable: boolean;
  renderCell?: (params: GridRenderCellParams<any, T, any>) => ReactNode;
  valueFormatter?: (params: GridValueFormatterParams<any>) => any;
  valueGetter?: (params: GridValueGetterParams<any, T>) => any;
  width?: number;
  minWidth?: number;
  flex?: number;
  hideable?: boolean;
  cellClassName?: GridCellClassNamePropType;
  renderHeader?: (params: GridColumnHeaderParams<any, T, any>) => ReactNode;
  type?: string;
  align?: GridAlignment;

  constructor(column: ITableColumn<T>) {
    this.field = column.field;
    this.headerName = column.headerName;
    this.sortable = column.sortable ?? false;
    this.renderCell = column.renderCell;
    this.valueFormatter = column.valueFormatter;
    this.valueGetter = column.valueGetter;
    this.width = column.width;
    this.minWidth = column.minWidth ?? column.width;
    if (!this.width) {
      this.flex = column.flex ?? 1;
    }
    this.hideable = column.hideable ?? true;
    this.cellClassName = column.cellClassName;
    this.renderHeader = column.renderHeader;
    this.type = column.type;
    this.align = column.align;
  }
}

type ITableLinkColumn<T extends GridValidRowModel> = ITableColumn<T> & {
  text?: (row: T) => string;
  linkProps: (row: T) => LinkProps;
};

function LinkCell<T extends GridValidRowModel>({ column, row }: { column: ITableLinkColumn<T>, row: GridRowModel<any> }) {
  const text = column.text ? column.text(row) : row[column.field];
  const linkProps = column.linkProps(row);
  const navigate = useNavigate();
  return (
    <Box className='MuiDataGrid-cellContent'>
      <Link title={text} onClick={() => navigate(linkProps.to)} sx={{ cursor: 'pointer' }}>
        {text}
      </Link>
    </Box>
  );
}

export class TableLinkColumn<T extends GridValidRowModel> extends TableColumn<T> {
  constructor(column: ITableLinkColumn<T>) {
    super(column);
    this.renderCell = ({ row }) => <LinkCell column={column} row={row}/>
  }
}

export class TableDateTimeColumn<T extends GridValidRowModel> extends TableColumn<T> {
  constructor(column: ITableColumn<T>) {
    super(column);
    this.valueFormatter = ({ value }) => new Date(value).toLocaleString();
    this.width = 80;
  }
}

interface TableAction<T> extends SelectItem {
  keepDataAfterAction?: boolean;
  confirm?: ConfirmOptions | ((row: T) => ConfirmOptions);
}

export interface TableGroupAction extends TableAction<unknown> {
  action: (ids: GridRowSelectionModel) => Promise<void | any>;
  confirm?: ConfirmOptions;
}

export interface TableRowAction<T> extends Omit<TableAction<T>, 'text'> {
  text: string | ((item: T) => string);
  action: (item: T) => Promise<void | any> | void;
  hide?: (item: T) => boolean;
}
