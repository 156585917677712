import ModelValidator from './model.validator';
import { DocumentEditDto } from '../../models/dto/document/document.edit.dto';

class CDocumentEditValidator extends ModelValidator<DocumentEditDto> {
  validate(model: DocumentEditDto, step: number): string[] {
    const errors: string[] = [];
    this.checkRequired(model.title, errors, 'title');
    this.checkLength(model.title, errors, 255, 'title');
    if (!!model.crmEntity?.type) {
      this.checkRequired(model.crmEntity.id, errors, 'crmEntity');
    }

    if (step > 0) {
      this.checkRequired(Object.keys(model.queue), errors, 'queue');
      if (!errors.includes('queue')) {
        this.check(model.queue, errors, (value) => Object.values(value).some((item: any) => item.initiator === true), 'initiator');
        this.check(model.queue, errors, (value) => {
          const orders = [...new Set(Object.values(value).map((item: any) => item.order ?? -1))].sort();
          return !orders.includes(-1) && orders.includes(0) && orders[orders.length - 1] === orders.length - 1;
        }, 'order');
      }
    }

    if (step > 1) {
      console.log(model.file)
      this.checkRequired(model.file, errors, 'file');
    }

    return errors;
  }

}

export const DocumentEditValidator = new CDocumentEditValidator();
