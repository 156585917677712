import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Modal from 'react-modal';
import moment from 'moment';
import 'moment/locale/ru';
import { MainPage } from "./page/main";
import LogPage from './page/log';
import UserPage from './page/user';
import { Settings } from "./page/Settings";
import DocumentPage from './page/document';
import UpdatePage from './page/update';
import DocflowPage from './page/docflow';
import SupportPage from './page/support';
import { AppContext } from './context/app.context';
import { DataStorage } from './enum/dataStorage';
import { Loader } from "./component/loader";
import { Rest } from './rest';
import Utils from './utils';
import i18n from './i18n';
import NotFoundRegistrationForm from './component/notFoundRegistrationForm';
import { AccessKey } from './enum/accessKey';
import { DocumentStatus } from './enum/documentStatus';
import { Header } from "./component/header";
import { DocumentDetails } from './page/DocumentDetails';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CustomAlert } from './component/layout/CustomAlert';
import { SnackbarProvider } from 'notistack';
import { TokenSign } from './page/TokenSign';
import { ConfirmProvider } from 'material-ui-confirm';
import { Trans } from 'react-i18next';
import { UserService } from './service/user.service';
import { DocumentList } from './page/DocumentList';
import { UserList } from './page/UserList';
import { SettingsService } from './service/settings.service';
import { Footer } from './component/layout/Footer';
import { DocumentEdit } from './page/DocumentEdit';
import { ruRU } from '@mui/material/locale';
import 'dayjs/locale/ru';
import { LocalizationProvider, ruRU as dpRu } from '@mui/x-date-pickers';
import { createTheme, ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FeatureFlagService } from './service/feature.flag.service';
import { PrivateDataConsentForm } from './page/PrivateDataConsentForm';
import { HttpClient } from './service/http.client';
import { ROBOTS } from './enum/robots';
import { ROBOT_DATA, SEND_TO_SIGN_ROBOT_DATA } from './service/robot.data';
import { ApiKeyInfoModal } from './component/ApiKeyInfoModal';
import { EmployeeDetails } from './page/EmployeeDetails';

const theme = createTheme(
  {
    typography: {
      allVariants: {
        color: '#4f4f4f',
      },
    },
    components: {
      MuiListItemIcon: {
        defaultProps: {
          sx: {
            // minWidth: '45px',
          },
        },
      },
    },
  },
  ruRU,
  dpRu,
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      keepPreviousData: true,
      retry: 0,
    },
  },
});

export default class App extends React.Component {

  constructor() {
    super();
    this.state = {
      appVersion: 7,
      settingsCache: {},
      settings: {},
      loading: true,
      needUpdate: false,
      openModalNotFoundRegisteredUsers: false,
      headerVisibility: true,
      isEmployee: false,
      isApiKeyLegal: false,
      featureFlag: {},
      showPDConsent: false,
      apiClients: null,
      availableClients: null,
      currentClient: undefined,
      apiKeyDialogOpen: false,
    };

    this.DefaultSettings = [
      new SettingsItem('AdminList', '', function () { return []; }),
      new SettingsItem('ExternalSystemClientId', '', function () { return ''; }),
      new SettingsItem('NotifyResponsible', '', function () { return 'Y'; }),
      new SettingsItem('NotifySigner', '', function () { return 'N'; }),
      new SettingsItem('NotificationSubscriptions', '', function () { return [DocumentStatus.denied, DocumentStatus.cancelled, DocumentStatus.signed]; }),
      new SettingsItem('Version', '', function () { return 0; }, false)
    ];

    Modal.setAppElement('#root');
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    const _ = this;
    try {
      const currentUser = await Rest.init();
      moment.locale(Rest.getLang());
      await i18n.changeLanguage(Rest.getLang());
      _.setState({
        currentUser: currentUser
      }, async () => {
        const appSettings = await _.initAppSettings();
        await _.checkPDConsent(currentUser);

        if (parseInt(_.state.settings.Version.Value) < _.state.appVersion) {
          _.setNeedUpdate();
        } else {
          if ((await HttpClient.post('/domain/auth')).data.domainAuth === false) {
            await _.setAppSettings('Version', 0);
            _.setNeedUpdate();
          }
        }

        const apiClients = await SettingsService.checkApikeyAuth(appSettings.ExternalSystemClientId.Value);
        if (apiClients == null) {
          _.setState({
            isEmployee: false,
            isApiKeyLegal: false,
          });
        } else {
          const availableClients = await this.filterOutAvailableClients(apiClients, currentUser);
          let chosen = Utils.getFromLocalStorage('client');
          if (!chosen || !availableClients.some((c) => c.value === chosen)) {
            chosen = availableClients[0]?.value ?? false;
            Utils.saveToLocalStorage('client', availableClients[0]?.value ?? false);
          }
          _.setState({
            apiClients: apiClients,
            availableClients: availableClients,
            isEmployee: availableClients.length > 0,
            isApiKeyLegal: true,
            currentClient: chosen,
          });
        }

        _.setState({ featureFlag: await FeatureFlagService.list() });

        _.setState({
          loading: false,
        });
      });
    }
    catch (err) {
      _.setState({
        loading: false
      });
    }
  }

  async filterOutAvailableClients(apiClient, currentUser) {
    const available = [];
    for (const client of apiClient) {
      if (currentUser?.ID && await UserService.checkEmployee(currentUser.EMAIL, client.value)) {
        available.push(client);
      }
    }
    return available;
  }

  async checkPDConsent (currentUser) {
    if (Rest.getUserOption('PD') === 'true') {
      const synced = (Rest.getAppOption('PD') ?? '').split(';');
      if (!synced.includes(currentUser.ID)) {
        synced.push(currentUser.ID);
        await UserService.setPDConsent(currentUser.ID, currentUser.EMAIL);
      }
      return;
    }

    const consent = await UserService.getPDConsent(currentUser.ID, currentUser.EMAIL);
    if (!consent) {
      this.setHeaderVisibility(false);
      window.history.replaceState({}, '', '/pd-consent');
      this.setState((prev) => ({ ...prev, showPDConsent: true }));
    } else {
      Rest.setUserOption('PD', 'true');
    }
  }

  setAppSettings = async (key, data, apiClients) => {
    const rd = {
      ENTITY: DataStorage.settings,
      NAME: key,
      ID: '',
      PROPERTY_VALUES: {
        VALUE: JSON.stringify(data)
      }
    };

    const defaultSettingsItem = this.DefaultSettings.find((item) => item.Key === key);
    this.setState((prev) => ({
      settings: {
        ...prev.settings,
        [key]: new SettingsItem(key, data, defaultSettingsItem.Default, defaultSettingsItem.SaveInSettings)
      },
    }));

    if (key === 'ExternalSystemClientId') {
      const availableClients = await this.filterOutAvailableClients(apiClients, this.state.currentUser);
      Utils.saveToLocalStorage('client', availableClients[0]?.value ?? false);
      await this.updateRobots(this.state.isApiKeyLegal, apiClients);
      this.setState({
        apiClients: apiClients,
        availableClients: availableClients,
        isApiKeyLegal: true,
        isEmployee: availableClients.length > 0,
        currentClient: availableClients[0]?.value ?? false,
      });
      await queryClient.refetchQueries('currentUser');
    }
    if (this.state.settingsCache[key]) {
      rd.ID = this.state.settingsCache[key].ID;
      await Rest.callMethod('entity.item.update', rd);
      return true;
    }
    else {
      await Rest.callMethod('entity.item.add', rd);
      return true;
    }
  }

  async updateRobots(keyUpdate, apiClients) {
    const existingRobots = (await Rest.callMethod('bizproc.robot.list', {}, true)).items;
    // removing existing robots
    for (const code of existingRobots) {
      try {
        await Rest.callMethod("bizproc.robot.delete", { CODE: code, });
      } catch (e) {}
    }

    // adding robots
    const robots = keyUpdate ? existingRobots : [ROBOTS.SEARCH_USER, ROBOTS.SEND_TO_SIGN, ROBOTS.DOC_STATUS, ROBOTS.SIGN_REPORT, ROBOTS.FIND_CLIENT]
    for (const CODE of robots) {
      try {
        let data;
        switch (CODE) {
          case ROBOTS.SEND_TO_SIGN:
            const sps = (await Rest.callMethod('crm.type.list', {}, true))?.items?.flatMap((item) => item.types ?? []) ?? [];
            data = SEND_TO_SIGN_ROBOT_DATA(sps, apiClients);
            break;
          default:
            data = ROBOT_DATA[CODE](apiClients);
        }

        await Rest.callMethod("bizproc.robot.add", data);
      } catch (e) {
        console.error(e);
      }
    }
  }

  initAppSettings = () => {
    const _ = this;
    return new Promise((resolve) => {
      _.setState({
        settingsCache: {}
      }, async () => {
        await _.getAppSettingsCache();
        var appSettings = {};
        _.DefaultSettings.forEach(function (item) {
          const appSettingsValue = _.getAppSettings(item.Key);
          appSettings[item.Key] = new SettingsItem(item.Key, appSettingsValue ? appSettingsValue : item.Default(), item.Default, item.SaveInSettings);
        });
        _.setState({
          settings: appSettings
        }, () => {
          resolve(appSettings);
        });
      });
    });
  }

  getAppSettingsCache = async () => {
    const settingsCache = {};
    try {
      const result = await Rest.callMethod('entity.item.get', { ENTITY: DataStorage.settings }, true);
      result.items.forEach(function (item) {
        settingsCache[item.NAME] = item;
      });
      this.setState({
        settingsCache: settingsCache
      });
    }
    catch (err) {
      console.error('getAppSettingsCache', err);
      this.setNeedUpdate();
    }
  }

  getAppSettings = (key) => {
    return this.state.settingsCache[key] ? Utils.tryParseJson(this.state.settingsCache[key].PROPERTY_VALUES.VALUE) : false;
  }

  updateComplete = () => {
    this.setState({
      needUpdate: false
    });
  }

  access = (key) => {
    const user = this.state.currentUser || null;
    if (!user)
      return false;

    switch (key) {
      case AccessKey.settings:
        if (this.state.settings && this.state.settings['AdminList']?.Value?.includes(user.Id)) {
          return true;
        }
        return user.isAdmin;
      case AccessKey.documents:
      case AccessKey.employees:
        return this.state.isApiKeyLegal && this.state.isEmployee;
      default:
        return user.isAdmin;
    }
  }

  getCurrentUser = () => {
    return this.state.currentUser;
  }

  setNeedUpdate() {
    this.setState({
      needUpdate: true
    });
  }

  setPDConsent = () => {
    this.setState({ showPDConsent: false });
  }

  setCurrentClient = (currentClient) => {
    Utils.saveToLocalStorage('client', currentClient);
    this.setState({ currentClient });
  }

  async tryCheckInstall() {
    //для ситуации когда переустановили с сохранением параметров
    try {
      const result = await Rest.callMethod('app.info');
      if (result.items && result.items.length > 0) {
        if (!result.items[0].INSTALLED) {
          this.setNeedUpdate();
          return false;
        }
        return true;
      }
    }
    catch (err) {
      console.error('tryCheckInstall', err);
      return false;
    }
  }

  onHideModal = () => {
    this.setState({
      openModalNotFoundRegisteredUsers: false
    });
  }

  setHeaderVisibility = (state) => {
    console.log('setHeaderVisibility', state);
    this.setState({
      headerVisibility: state
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="app container-fluid my-2">
          <div className="text-center">
            <Loader size={60} />
          </div>
        </div>
      );
    }

    if (!Rest.isInitComplete()) {
      return null;
    }

    return (
      <AppContext.Provider value={{
        appVersion: this.state.appVersion,
        isApiKeyLegal: this.state.isApiKeyLegal,
        getCurrentUser: this.getCurrentUser,
        settings: this.state.settings, initAppSettings: this.initAppSettings, setAppSettings: this.setAppSettings, updateComplete: this.updateComplete,
        access: this.access, setHeaderVisibility: this.setHeaderVisibility,
        featureFlag: this.state.featureFlag,
        setPDConsent: this.setPDConsent,
        currentClient: this.state.currentClient,
        setCurrentClient: this.setCurrentClient,
        apiClients: this.state.apiClients,
        availableClients: this.state.availableClients,
        toggleApiInfoDialog: (val) => this.setState({ apiKeyDialogOpen: val })
      }}>
        <QueryClientProvider client={queryClient} contextSharing={true}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
              <SnackbarProvider
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                Components={{
                  error: CustomAlert,
                }}
              >
                <ConfirmProvider defaultOptions={{
                  title: <Trans>confirm.title</Trans>,
                  confirmationText: <Trans>button.ok</Trans>,
                  cancellationText: <Trans>button.cancel</Trans>,
                  dialogProps: { maxWidth: 'sm' }
                }}>
                  <BrowserRouter>
                    <div className="app container-fluid my-2">
                      {!this.state.loading && (this.state.showPDConsent || !this.state.needUpdate) &&
                        <>
                          {this.state.headerVisibility &&
                            <Header apiClients={this.state.availableClients} />
                          }
                          <Routes>
                            <Route path="/docflow" element={<DocflowPage/>} exact />
                            <Route path="/support" element={<SupportPage/>} />
                            <Route path="/users" element={<UserList/>} />
                            <Route path="/user/:id" element={<UserPage/>} />
                            <Route path="/log" element={<LogPage/>} />
                            <Route path="/settings" element={<Settings/>} />
                            <Route path="/docflow/details/:guid" element={<DocumentDetails/>} />
                            <Route path="/docflowinternal/details/:guid" element={<DocumentDetails/>} />
                            <Route path="/docflowinternal/create" element={<DocumentEdit internal/>}  exact />
                            <Route path="/docflowinternal" element={<DocumentList internal/>}  exact />
                            <Route path="/document/:id" element={<DocumentPage/>} />
                            <Route path="/token-sign" element={<TokenSign/>} />
                            <Route path="/pd-consent" element={<PrivateDataConsentForm />} exact/>
                            <Route path="/employee/:email" element={<EmployeeDetails/>} />
                            <Route path="/" element={<MainPage/>} />
                          </Routes>
                          <Footer />
                          <ApiKeyInfoModal isOpen={this.state.apiKeyDialogOpen} />
                        </>
                      }
                      {!this.state.showPDConsent && this.state.needUpdate &&
                        <UpdatePage currentVersion={this.state.settings.Version.Value} currentUser={this.state.currentUser} />
                      }
                      <NotFoundRegistrationForm isOpen={this.state.openModalNotFoundRegisteredUsers} onHide={this.onHideModal} />
                    </div>
                  </BrowserRouter>
                </ConfirmProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </AppContext.Provider>
    );
  }
}

class SettingsItem {
  constructor(key, value, funcDefault, saveInSettings = true) {
    this.Key = key;
    this.Value = value;
    this.Default = funcDefault;
    this.SaveStatus = true;
    this.SaveInSettings = saveInSettings;
  }
}