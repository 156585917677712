import { DocumentDetails } from "../../models/interfaces";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useMutation, useQueryClient } from "react-query";
import { useErrorHandler } from "../../hook/useErrorHandler";
import { DocumentService } from "../../service/document.service";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  document: DocumentDetails;
  employee: string;
}

export function RejectDialog({ isOpen, onClose, document, employee }: IProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const handleError = useErrorHandler();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { mutateAsync: reject } = useMutation(async () => {
    if (!comment) {
      setError(t("signReject.error"));
      return;
    }

    setError("");
    setLoading(true);
    try {
      await DocumentService.reject(
        document.uuid,
        document.guid,
        employee,
        comment
      );
      await queryClient.refetchQueries(`document_details_${document.guid}`);
      onClose()
    } catch (e) {
      handleError("reject", e);
    } finally {
      setLoading(false);
    }
  });

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t("signReject.title")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t("signReject.comment")}
          fullWidth
          variant="outlined"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          helperText={error}
          error={!!error}
          sx={{ width: '400px' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("button.cancel")}</Button>
        <LoadingButton
          loading={loading}
          color="error"
          variant="outlined"
          onClick={() => reject()}
        >
          {t("button.ok")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
