import { HttpClient } from './http.client';
import { EntityQueryKey } from '../enum/query.keys';
import { AutocompleteResponse } from '../models/interfaces/common';

class CAutocompleteService {

  async fetch(entity: EntityQueryKey, term: string|(string|number)[], skip = 0, filter: Record<string, any> = {}) {
    if (Array.isArray(term) && !term.length) return { items: [], nextOffset: 0 };
    return (await HttpClient.post<AutocompleteResponse>(`/${entity}/autocomplete`, {
      term: Array.isArray(term) ? undefined : term,
      identifiers: Array.isArray(term) ? term : undefined,
      skip,
      filter,
    })).data;
  }

  fetchFn = (entity: EntityQueryKey, term: string|(string|number)[], skip?: number) => this.fetch(entity, term, skip);
}

export const AutocompleteService = new CAutocompleteService();