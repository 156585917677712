import { OCSP_ERRORS, TSP_ERRORS } from '../constants';

export class CryptoproError extends Error {
  readonly code?: string;
  readonly _description?: string;
  readonly isTsp?: boolean;
  readonly isOcsp?: boolean;

  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, CryptoproError.prototype);
    this.code = message.match(/(\()(0x.+)(\))/)?.[2];
    if (this.code) {
      this._description = TSP_ERRORS[this.code] ?? OCSP_ERRORS[this.code];
      this.isTsp = this.code in TSP_ERRORS;
      this.isOcsp = this.code in OCSP_ERRORS;
    }
  }

  get description() {
    return this._description ? `${this._description} (${this.code})` : this.message;
  }
}