import { DocumentStatus } from "./enum/documentStatus";
import { Query } from 'react-query';
import { CrmEntityType, SignxDocumentStatus, SignxSignerStatusEnum } from './models/enum';
import { CrmEntity } from './models/interfaces';
import { Rest } from './rest';

class Utils {
    static saveToLocalStorage(key: string, value: any) {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        }
        catch (err) {
            console.warn(err)
        }
    }

    static getFromLocalStorage(key: string) {
        try {
            const value = localStorage.getItem(key);
            if (value) {
                return JSON.parse(value);
            }
        }
        catch (err) {
            console.warn(err);
            return null;
        }
    }

    static getStringArray(str: string): string[] {
        var result: string[] = [];
        if (!str)
            return result;
        var array = str.toString().split(';');
        for (var i in array) {
            result.push(array[i]);
        }
        return result;
    }

    static tryParseJson(text: string) {
        if (!text || text.length < 1)
            return false;
        let obj;
        try {
          obj = JSON.parse(text);
        } catch (e) {
          console.warn(text);
          console.warn(e);
          obj = false;
        }
        return obj;
    }

    static getArrayTimeFromSeconds(totalSeconds: number) {
        const hours = Math.round(totalSeconds / 3600);
        const mins = Math.round((totalSeconds - hours * 3600) / 60);
        const seconds = totalSeconds % 60;
        return [hours, mins, seconds];
    }

    static secondsToString(value: number) {
        var hours = Math.floor(value / 60 / 60);
        var minutes = Math.floor(value / 60) - (hours * 60);
        var seconds = value % 60;
        if (hours < 1) {
            return [
                minutes.toString().padStart(2, '0'),
                seconds.toString().padStart(2, '0')
            ].join(':');
        }
        return [
            hours.toString().padStart(2, '0'),
            minutes.toString().padStart(2, '0'),
            seconds.toString().padStart(2, '0')
        ].join(':');
    }

    static convertFileBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                if (fileReader.result && typeof fileReader.result === 'string') {
                    resolve(fileReader.result?.split(',')[1]);
                }
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        });
    }

    static getError(err: any) {
        if (err.response) {
            return err.response.data;
        }
        return err;
    }

    static getDocumentStatusClass(status: string) {
        let statusClass = '';
        if (status === DocumentStatus.emailComplitedDoc) {
            statusClass = 'text-success fw-bold';
        }
        if (status === DocumentStatus.awaitingSignatures) {
            statusClass = 'text-warning fw-bold';
        }
        if (status === DocumentStatus.sendError || status === DocumentStatus.denied || status === DocumentStatus.cancelled) {
            statusClass = 'text-danger fw-bold';
        }
        return statusClass;
    }

    static searchPrefix(value: string) {
        return `<${value}>`;
    }

    static resetQueryFn(query: Query, ...keys: any[]): boolean {
        if (!keys?.length) {
            console.error("No query keys provided for reset function. It's probably an error.");
        }
        return (keys ?? []).some((key) => query.queryKey.includes(key));
    };

    static getColor(status: string) {
        switch (status) {
            case SignxSignerStatusEnum.PENDING:
            case SignxSignerStatusEnum.SENT:
            case SignxSignerStatusEnum.SENT_TO_GOSKEY:
            case SignxDocumentStatus.SIGNING:
                return 'warning';
            case SignxSignerStatusEnum.SIGNED:
            case SignxDocumentStatus.SIGNED:
                return 'success';
            case SignxSignerStatusEnum.REJECTED:
            case SignxSignerStatusEnum.ERRORED:
            case SignxDocumentStatus.DELETED:
            case SignxDocumentStatus.REVOKED:
            case SignxDocumentStatus.REJECTED:
            case SignxDocumentStatus.ERRORED:
                return 'error';
            default:
                return 'info';
        }
    }

    static getIcon(status: string) {
        switch (status) {
            case SignxDocumentStatus.DRAFT:
                return 'draft';
            case SignxSignerStatusEnum.PENDING:
                return 'alarm';
            case SignxSignerStatusEnum.SENT:
            case SignxSignerStatusEnum.SENT_TO_GOSKEY:
            case SignxDocumentStatus.SIGNING:
                return 'schedule';
            case SignxSignerStatusEnum.SIGNED:
            case SignxDocumentStatus.SIGNED:
                return 'check_circle';
            case SignxSignerStatusEnum.REJECTED:
            case SignxDocumentStatus.REJECTED:
                return 'cancel'
            case SignxSignerStatusEnum.ERRORED:
                return 'error';
            case SignxDocumentStatus.REVOKED:
                return 'undo';
            case SignxDocumentStatus.DELETED:
                return 'do_not_disturb_on';
            case SignxSignerStatusEnum.NONE:
                return 'event_list';
        }
    }

    static getEntityLink(crmEntity: CrmEntity | null, currentUser: any) {
        if (crmEntity == null) return '';
        switch (crmEntity.type) {
            case CrmEntityType.deal:
            case CrmEntityType.lead:
            case CrmEntityType.company:
            case CrmEntityType.contact:
            case CrmEntityType.quote:
                return `https://${Rest.domain}/crm/${crmEntity.type}/details/${crmEntity.id}/`;
            case CrmEntityType.task:
                return `https://${Rest.domain}/company/personal/user/${currentUser?.ID}/tasks/task/view/${crmEntity.id}/`;
            default:
                return `https://${Rest.domain}/crm/type/${crmEntity.type}/details/${crmEntity.id}/`;
        }
    }

    private static fileSizeUnit(iter: number) {
        switch (iter) {
            case 0:
                return 'byte';
            case 1:
                return 'kilobyte';
            default:
                return 'megabyte';
        }
    }
    static formatFileSize(contentLength: number | undefined): [unit: string, params: any] {
        let size = contentLength ?? 0;
        let iter = 0;
        while (size > 1024 && iter < 2) {
            iter++;
            size /= 1024;
        }
        return ['file.size', { size: size, unit: Utils.fileSizeUnit(iter) }];
    }
}

export default Utils;