import { DataTable } from "../../component/DataTable";
import { useColumns } from "./table.definition";
import { EntityQueryKey } from '../../enum/query.keys';
import { UserService } from '../../service/user.service';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../hook/useCurrentUser';

export function UserList() {
  const columns = useColumns();
  const { t } = useTranslation();
  const currentUser = useCurrentUser();

  return (
    <div>
      <DataTable
        id="user-list"
        queryKey={EntityQueryKey.User}
        columns={columns}
        fetchData={(data) =>
          UserService.list({ ...data })
        }
        itemId={"uuid"}
        toolbarActions={[{ text: t('button.profile'), value: `/employee/${currentUser?.EMAIL}` }]}
      />
    </div>
  );
}
