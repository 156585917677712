import {
  GridColumnVisibilityModel,
  GridFooterContainer,
  GridRowSelectionModel,
  FooterPropsOverrides,
  GridFooter,
} from '@mui/x-data-grid';
import React, { useState } from 'react';
import { TableColumn, TableGroupAction } from './table.model';
import { TableColumnsButton } from './table.columns.button';
import { QueryKey } from 'react-query';

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    selectionModel: GridRowSelectionModel;
    setSelectionModel: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
    groupActions: TableGroupAction[];
    columns: TableColumn<any>[];
    columnVisibilityModel: GridColumnVisibilityModel;
    columnVisibilityChange: (newVisibilityModel: GridColumnVisibilityModel) => void;
    setPanelAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    setPanelPlacement: React.Dispatch<React.SetStateAction<string | undefined>>;
    refetch: () => Promise<any>;
    queryKey: QueryKey;
  }
}

export function TableFooter(props: FooterPropsOverrides) {
  const [columnsAnchor, setColumnsAnchor] = useState<HTMLElement | null>(null);

  return (
    <>
      <GridFooterContainer
        ref={setColumnsAnchor}
        sx={{
          backgroundColor: 'white',
          margin: '0 -1px -1px -1px',
        }}
      >
        <GridFooter sx={{ width: '100%', '.MuiDataGrid-selectedRowCount': { display: 'none' } }} />
        <TableColumnsButton
          setPanelAnchor={props.setPanelAnchor}
          panelAnchor={columnsAnchor}
          setPanelPlacement={props.setPanelPlacement}
        />
      </GridFooterContainer>
    </>
  );
}
