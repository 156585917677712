import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Chip,
  FormControl,
  FormHelperText, IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from '@mui/material';
import ValidationContext from '../../../hook/UseValidation/validation.context';
import styled from '@emotion/styled';
import { InputControlProps } from '../input.control.props';
import { useTranslation } from 'react-i18next';
import { useValidation } from '../../../hook/UseValidation';
import { SelectItem } from '../../../models/interfaces';
import { Clear } from '@mui/icons-material';

interface IProps extends InputControlProps<number | string> {
  options: SelectItem[];
  nullable?: boolean;
  multiple?: boolean;
  sx?:  SxProps<Theme>;
}

const StyledMuiMenuItem = styled(MenuItem)`
  word-break: normal;
  white-space: pre-line;
  z-index: 2000;
`;

export default function SelectControl(props: IProps) {
  const { t } = useTranslation();
  const validate = useValidation(props.validators);
  const { validateOn } = useContext(ValidationContext);
  const [errorText, setErrorText] = useState<string>();
  const select = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setErrorText(validate(props.value, props.tab ?? 'main'));
  }, [props.value, validateOn]);

  return (
    <FormControl fullWidth required={props.required} error={!!errorText} size="small" margin="dense" sx={props.sx}>
      <InputLabel>{t(props.labelKey)}</InputLabel>
      <Select
        ref={select}
        label={t(props.labelKey)}
        value={props.value}
        onChange={(event: SelectChangeEvent) => {
          props.onChange(
            event.target.value,
            props.options.find((o) => o.value === event.target.value),
          );
        }}
        multiple={props.multiple}
        disabled={props.disabled}
        renderValue={
          props.multiple
            ? (selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {props.options
                    .filter((item) => selected.includes(item.value as string))
                    .map((item) => (
                      <Chip
                        key={item.value}
                        label={item.text}
                        onDelete={() => {
                          props.onChange(props.value?.filter((v: any) => v !== item.value));
                          if (select.current != null) {
                            select.current.blur(); //TODO при удалении всех не снимается фокус с поля. Нужно это исправить.
                          }
                        }}
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    ))}
                </Box>
              )
            : undefined
        }
        sx={{
          '& .select-close-btn': { display: 'none' },
          '&:hover .select-close-btn': { display: props.value? 'inline-flex' : 'none' },
        }}
        endAdornment={(
            <IconButton
              className="select-close-btn"
              sx={{ marginRight: '15px' }}
              aria-label="clear select"
              onClick={() => props.onChange('')}
              edge="end"
            >
              <Clear fontSize="small" />
            </IconButton>
        )}
      >
        {props.nullable && (
          <StyledMuiMenuItem key={-1} value="null">
            <em>{t('select.notSet')}</em>
          </StyledMuiMenuItem>
        )}
        {props.options.map((option, idx) => (
          <StyledMuiMenuItem key={idx} value={option.value}>
            {t(option.text)}
          </StyledMuiMenuItem>
        ))}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
}
