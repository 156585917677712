import { TabProps } from "./tab.props";
import { Box, Typography, Link, Stack, Avatar, Icon, useTheme, Tooltip } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Rest } from "../../rest";
import {useMemo} from "react";
import Utils from '../../utils';
import { useCurrentUser } from '../../hook/useCurrentUser';

export function InfoTab({ index, tab, document }: TabProps) {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const signers = useMemo(() => {
      if (!document.signers) {
          return [];
      }
      return document.signers.sort((a, b) => a.order > b.order ? 1 : -1);
  }, [document]);
  const theme = useTheme();
  return index === tab ? (
    <Stack direction="column" spacing={3}>
      <Box>
        <Typography variant={"body2"} fontSize="small" textTransform="uppercase">
          {t("common.field.guid")}
        </Typography>
        <small>{document.guid}</small>
      </Box>
      <Box>
        <Typography variant={"body2"} fontSize="small" textTransform="uppercase">
          {t("documentDetails.status")}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1} color={theme.palette[Utils.getColor(document.status)].main}>
          <Icon className="material-symbols-outlined" fontSize="small">{Utils.getIcon(document.status)}</Icon>
          <span>{t(`documentHistory.DOCUMENT_STATUS_CHANGE.${document.status}`)}</span>
        </Stack>
      </Box>
      {document.crmEntity && (<Box>
        <Typography variant={"body2"} fontSize="small" textTransform="uppercase">
          {t("documentDetails.crmLink")}
        </Typography>
        <small>{t(`crmEntity.${document.crmEntity.type}`, document.crmEntity.typeView)}:{" "}
        <Link
          target="_blank"
          href={Utils.getEntityLink(document.crmEntity, currentUser)}
        >
          {document.crmEntity.view}
        </Link>
        </small>
      </Box>)}
      <Box>
        <Typography variant={"body2"} fontSize="small" textTransform="uppercase" mb={1}>
          {t("documentDetails.owner")}
        </Typography>
        <Stack direction="row" spacing={2}>
          <Avatar src={document.owner.avatar}/>
          <Stack direction="column">
            {document.owner.id ? <Link href={`https://${Rest.domain}/company/personal/user/${document.owner.id}/`} target="_blank">{document.owner.name}</Link> : <span>{document.owner.name}</span>}
            <Typography variant={"body2"}><small>{document.owner.client?.name}</small></Typography>
          </Stack>
        </Stack>
      </Box>
      {document.signers && (<Box>
        <Typography variant={"body2"} fontSize="small" textTransform="uppercase" mb={1}>
          {t("documentDetails.signers")}
        </Typography>
        <Stack direction="column" spacing={1}>
          {signers.map((signer) =>
            <Stack direction="row" spacing={2}>
              <Avatar src={signer.employee.avatar}/>
              <Stack direction="column">
                {signer.employee.id ? <Link href={`https://${Rest.domain}/company/personal/user/${signer.employee.id}/`} target="_blank">{signer.employee.name}</Link> : signer.employee.name}
                <Typography variant={"body2"}><small>{signer.employee.client?.name}</small></Typography>
                <Stack direction="row" alignItems="center" spacing={1} color={theme.palette[Utils.getColor(signer.status)].main}>
                  <Icon className="material-symbols-outlined" fontSize="small">{Utils.getIcon(signer.status)}</Icon>
                  <Tooltip title={signer.comment} placement="top-start">
                    <small style={{
                      borderBottom: signer.comment ? '1px dashed' : undefined,
                      cursor: signer.comment ? 'pointer' : undefined,
                    }}>{t(`signerStatus.${signer.status}`)}</small>
                  </Tooltip>
                </Stack>
                {signer.initiator && <small>{t('documentDetails.initiator')}</small>}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Box>)}
    </Stack>
  ) : null;
}
