import React from 'react';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { Loader } from "../component/loader";
import UserSelect from '../component/select/user';
import FieldErrors from '../component/fieldError';
import { AppContext } from '../context/app.context';
import { DataStorage } from '../enum/dataStorage';
import { Rest } from '../rest';
import LogService from '../service/logService';
import { LogType } from '../enum/logType';
import { ObjectType } from '../enum/objectType';
import Utils from '../utils';
import ValidationError from '../service/fieldValidator/validationError';
import FieldValidator from '../service/fieldValidator/fieldValidator';
import { Button, TextField } from '@mui/material';

export default class UserPage extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super();
        this.state = {
            isLoaded: false,
            loading: true,
            id: parseInt(props.match?.params.id) || 0,
            dataId: 0,
            user: null,
            responsible: null,
            email: '',
            errors: {}
        };
    }

    componentDidMount() {
        this.refreshData();
    }

    async refreshData() {
        const _ = this;
        if (_.state.id < 1) {
            _.setState({
                isLoaded: true,
                loading: false
            }, () => { Rest.resizeFrame(); });
            return;
        }

        _.setState({
            loading: true
        });

        try {
            const entityUsers = await Rest.getUsers([_.state.id]);
            const entity = entityUsers[_.state.id];
            if (!entity) {
                _.setState({
                    isLoaded: true,
                    loading: false
                }, () => { Rest.resizeFrame(); });
                return;
            }

            const userSignData = await Rest.getUserSignData(entity.Id);
            const responsibles = [];
            if (userSignData) {
                const resposiblesId = userSignData.ResponsibleId;
                if (resposiblesId) {
                    const users = await Rest.getUsers(resposiblesId);
                    resposiblesId.forEach(x => {
                        if (users[x]) {
                            responsibles.push({ value: users[x].Id, label: users[x].FullName });
                        }
                    });
                }
            }
            _.setState({
                dataId: userSignData ? parseInt(userSignData.ID) || 0 : '',
                user: { value: entity.Id, label: entity.FullName },
                email: userSignData ? userSignData.PROPERTY_VALUES.EMAIL : '',
                responsible: responsibles.length > 0 ? responsibles : null,
                isLoaded: true,
                loading: false
            }, () => { Rest.resizeFrame(); });
        }
        catch (err) {
            console.error('user detail', err);
        }
    }

    async validateUser() {
        const errors = this.state.errors;
        if (!this.state.user) {
            errors['user'] = [];
            this.setState({
                errors: errors
            });
            return;
        }

        try {
            const userSignData = await Rest.getUserSignData(this.state.user.value);
            if (userSignData && userSignData.Id !== this.state.dataId) {
                errors['user'] = [new ValidationError('user-validate-duplicate')];
            }
            else {
                errors['user'] = [];
            }
            this.setState({
                errors: errors
            });
        }
        catch (err) {
            console.error('validateUser', err);
        }
    }

    onUserChange = (selected) => {
        const _ = this;
        _.setState({
            user: selected
        }, () => { _.validateUser() });
    }

    onResponsibleChange = (selected) => {
        const _ = this;
        _.setState({
            responsible: selected
        });
    }

    onEmailChange = (e) => {
        this.setState({
            email: e.target.value
        });
    }

    getRequestData() {
        const responsible = this.state.responsible ? this.state.responsible.map(x => x.value) : [];
        const result = {
            ENTITY: DataStorage.user,
            ID: this.state.dataId > 0 ? this.state.dataId : '',
            NAME: 'user',
            PROPERTY_VALUES: {
                USER: this.state.user.value,
                EMAIL: this.state.email,
                RESPONSIBLE: JSON.stringify(responsible)
            }
        };
        if (!responsible.includes(this.state.user.value)) {
            responsible.push(this.state.user.value);
        }
        result.PROPERTY_VALUES.RESPONSIBLE_SEARCH = responsible.map(x => Utils.searchPrefix(x)).join(';');
        return result;
    }

    validateFields() {
        const { errors } = this.state;
        const fields = [
            {
                name: 'email', checks: [
                    { check: !this.state.user, error: new ValidationError('field-email', { field: 'Y' }) }
                ]
            },
            {
                name: 'signatures', checks: [
                    { check: (!this.state.email || this.state.email.length < 1), error: new ValidationError('field-email', { field: 'Y' }) }
                ]
            }
        ]

        const result = FieldValidator.validate(fields, errors);
        if (result.updateState) {
            this.setState({
                errors: errors
            }, () => {
                Rest.resizeFrame();
            });
        }
        return result.isValid;
    }

    onSubmit = (e) => {
        if (e) e.preventDefault();

        if (!this.validateFields()) {
            return;
        }

        const _ = this;
        const rd = _.getRequestData();
        const link = _.state.id > 0 ? 'entity.item.update' : 'entity.item.add';

        if (_.state.id > 0) {
            Rest.clearCacheUser(_.state.id);
        }

        const currentUserId = _.context.getCurrentUser().Id;
        Rest.callMethod(link, rd).then(result => {
            if (result.items.length > 0 && _.state.id < 1) {
                new LogService(LogType.UserAdd, currentUserId, parseInt(rd.PROPERTY_VALUES.USER) || 0, ObjectType.user).add();
                /*_.setState({
                    id: parseInt(rd.PROPERTY_VALUES.USER) || 0
                }, () => {
                    _.props.history.push('/users');
                });*/
            }
            else {
                new LogService(LogType.UserEdit, currentUserId, _.state.id, ObjectType.user).add();
            }
            _.props.history.push('/users');
        }).catch(err => {
            console.log('err', err);
        });
    }

    clickDelete = async (e) => {
        const _ = this;
        if (e) e.preventDefault();
        try {
            const result = Rest.callMethod('entity.item.delete', { ENTITY: DataStorage.user, ID: this.state.dataId });
            new LogService(LogType.UserDelete, this.context.getCurrentUser().Id, this.state.id, ObjectType.user).add();
            this.props.history.push('/users');
        }
        catch (err) {
            console.error('clickDelete user', err);
        }
    }

    render() {
        if (this.loading && !this.isLoaded) {
            return (
                <div>
                    <Loader />
                </div>
            );
        }

        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <div className="row mt-4">
                        <div className="col-12 col-lg-6 col-xl-4">
                            <div className="form-outline">
                                <UserSelect value={this.state.user} onChange={this.onUserChange} title="field-user" required />
                                <FieldErrors errors={this.state.errors['user']} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 col-lg-6 col-xl-4">
                            <TextField type="email" value={this.state.email} onChange={this.onEmailChange} label={i18next.t('field-email')} required />
                            <FieldErrors errors={this.state.errors['email']} />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 col-lg-6 col-xl-4">
                            <div className="form-outline">
                                <UserSelect value={this.state.responsible} onChange={this.onResponsibleChange} title="field-sign-responsibles" isMulti={true} />
                                <FieldErrors errors={this.state.errors['responsible']} />
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <button type="submit" className="btn btn-primary me-2"><Trans>btn-save</Trans></button>
                        {this.state.dataId > 0 &&
                            <button type="button" className="btn btn-outline-danger me-2" onClick={this.clickDelete}><Trans>btn-delete</Trans></button>
                        }
                        <button type="button" className="btn btn-outline-primary"><Trans>btn-cancel</Trans></button>
                    </div>
                </form>
            </div>
        );
    }
}