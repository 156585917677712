import { TableColumn, TableGroupAction, TableRowAction } from './table.model';
import { QueryKey } from 'react-query';
import { GridValidRowModel } from '@mui/x-data-grid';
import { ListRequestDto, ListResponseDto, SelectItem } from '../../models/interfaces';
import { FilterItem } from "../../models/filter";
import { BaseEntity } from '../../models/interfaces/common';

export interface DataTableProps<TModel extends BaseEntity & GridValidRowModel, TFilter extends Record<string, any> = TModel> {
  id: string;
  queryKey: QueryKey;
  itemId: string | ((item: any) => string);
  columns: TableColumn<TModel>[];
  fetchData: (requestData: ListRequestDto<TFilter>) => Promise<ListResponseDto<TModel>>;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  onPage?: number;
  filter?: FilterItem[];
  rowActions?: TableRowAction<TModel>[];
  groupActions?: TableGroupAction[];
  toolbarActions?: SelectItem[]; // пока достаточно, потом мб расширим
}

export const DEFAULT_DATA_TABLE_PROPS: Partial<DataTableProps<any>> = {
  sortOrder: 'asc',
  onPage: 10,
  filter: [],
  rowActions: [],
  groupActions: [],
  toolbarActions: [],
};
