import React, { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Utils from '../utils';
import { Rest } from '../rest';
import { TextField } from '@mui/material';

export default function NotFoundRegistrationForm(props) {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [comment, setComment] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentUserAuthData = Rest.getAuth();
    if (!currentUserAuthData)
      return;

    try {
      currentUserAuthData.name = name;
      currentUserAuthData.email = email;
      currentUserAuthData.phone = phone;
      currentUserAuthData.companyName = companyName;
      currentUserAuthData.comment = comment;
      const result = await axios.post('/register', currentUserAuthData);

      const data = result.data;
      if (data.result) {
        props.onHide();
      }
      else {
        console.log('handleSubmit result', data);
      }
    }
    catch (err) {
      console.error('handleSubmit', Utils.getError(err));
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onHide}
      className="modal-dialog modal-lg"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          overflowY: "scroll"
        }
      }}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 class="modal-title">{t('msg-not-found-registered-users')}</h5>
          <button type="button" class="btn-close" aria-label="Close" onClick={props.onHide} />
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <TextField type='text' label={t('field-fio')} onChange={(e) => setName(e.target.value)} value={name} />
              </div>
              <div className="col-12 mt-3">
                <TextField type='email' label={t('field-email')} onChange={(e) => setEmail(e.target.value)} value={email} required />
              </div>
              <div className="col-12 mt-3">
                <TextField type='tel' label={t('field-phone')} onChange={(e) => setPhone(e.target.value)} value={phone} />
              </div>
              <div className="col-12 mt-3">
                <TextField type='text' label={t('field-company-name')} onChange={(e) => setCompanyName(e.target.value)} value={companyName} />
              </div>
              <div className="col-12 mt-3">
                <TextField textarea rows={4} label={t('field-comment')} onChange={(e) => setComment(e.target.value)} value={comment} />
              </div>
              <div className="mt-3">
                <button type="submit" className="btn btn-primary">{t('btn-save')}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}