import { DocumentService } from '../../service/document.service';
import { EntityQueryKey } from '../../enum/query.keys';
import { DataTable } from '../../component/DataTable';
import { useColumns } from './table.definition';
import { useActions } from './action.definition';
import { useFilter } from './filter.definition';

interface IProps {
  internal?: boolean;
}

export function DocumentList({ internal }: IProps) {
  const columns = useColumns();
  const filter = useFilter();
  const [toolbarActions] = useActions(internal);

  return (
    <div>
      <DataTable
        id="document-list"
        queryKey={[EntityQueryKey.Document, internal]}
        columns={columns}
        filter={filter}
        toolbarActions={toolbarActions}
        fetchData={(dto) => {
          if (!dto) {
            dto = { filter: {} };
          }
          if (!dto.filter) {
            dto.filter = {};
          }
          dto.filter['internal'] = internal;
          return DocumentService.list(dto)
        }}
        itemId={"guid"}
      />
    </div>
  );
}