import { InputControlProps } from '../input.control.props';
import { CloudUpload, Delete, PictureAsPdf, PhotoLibrary } from '@mui/icons-material';
import React, { DragEvent, useState, useContext, useEffect, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormHelperText, FormLabel, Icon, Stack, styled, Typography } from '@mui/material';
import { FileDto } from '../../../models/dto/common';
import { useValidation } from '../../../hook/UseValidation';
import ValidationContext from '../../../hook/UseValidation/validation.context';
import { useSnackbar } from 'notistack';
import Utils from '../../../utils';

interface IProps extends InputControlProps<FileDto[]> {
  accept?: string;
  limit?: number;
  maxSize?: number;
  value: FileDto[];
}

const StyledLabel = styled('label')({
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    '&:hover p,&:hover svg,& img': {
      opacity: 1,
    },
    '& p, svg': {
      opacity: 0.4,
    },
    '&:hover img': {
      opacity: 0.3,
    },
});

export function FileControl({ limit = 1, accept, maxSize = 5242880, ...props }: IProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const validate = useValidation(props.validators);
  const { validateOn } = useContext(ValidationContext);
  const [errorText, setErrorText] = useState<string>();
  const [labelText, setLabelText] = useState(t('file.control.hoverText'));
  const [isDragOver, setIsDragOver] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const stopDefaults = (e: DragEvent<HTMLElement>) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const updateFileList = async (newFiles: FileList) => {
    const dtos = props.value;
    for (let i = 0; i < limit - props.value.length; i++) {
      const file = newFiles.item(i);
      if (file === null) break;

      if (file.size > maxSize) {
        enqueueSnackbar(
          t('file.control.tooLargeFile', [t(...Utils.formatFileSize(maxSize))]),
          { variant: 'error' },
        );
        continue;
      }

      const dto = await FileDto.fromBlob(file);
      if (accept === undefined || accept.includes(dto.type)) {
        dtos.push(dto);
      }
    }
    if (inputRef.current) {
      inputRef.current.files = new DataTransfer().files;
    }
    props.onChange(dtos);
  };

  const dragEvents = useMemo(() => ({
    onDragEnter: (e: DragEvent<HTMLElement>) => {
      stopDefaults(e)
      setIsDragOver(true)
      setLabelText(t('file.control.dropLabel'))
    },
    onDragLeave: (e: DragEvent<HTMLElement>) => {
      stopDefaults(e)
      setIsDragOver(false)
      setLabelText(t('file.control.hoverText'))
    },
    onDragOver: stopDefaults,
    onDrop: (e: DragEvent<HTMLElement>) => {
      stopDefaults(e)
      setLabelText(t('file.control.hoverText'))
      setIsDragOver(false)
      return updateFileList(e.dataTransfer.files);
    },
  }), []);

  const getIcon = useCallback((type: string) => {
    switch (type) {
      case 'image/jpeg':
      case 'image/png':
        return <PhotoLibrary/>;
      case 'application/pdf':
        return <PictureAsPdf/>;
      default:
        return null;
    }
  }, []);

  const handleRemoveFile = useCallback((files: FileDto[], idx: number) => {
    files.splice(idx, 1);
    props.onChange(files);
  }, []);

  useEffect(() => {
    setErrorText(validate(props.value, props.tab ?? 'main'));
  }, [props.value, validateOn]);

  return (
    <FormControl fullWidth required={props.required} error={!!errorText} size="small" margin="dense">
      <FormLabel>{t(props.labelKey)}</FormLabel>
      <Stack direction="column" sx={{ my: 2 }}>
        {props.value.map((file, idx) => <Stack key={idx} direction="row">
          {/*<Icon sx={{mr: 1}}>{getIcon(file.type)}</Icon>*/}
          <span>{file.name}</span>
          <Delete sx={{ ml: 1, cursor: 'pointer' }} color="error" onClick={() => handleRemoveFile(props.value, idx)}/>
        </Stack>)}
      </Stack>
      <input onChange={e => updateFileList(e.target.files ?? new FileList())} ref={inputRef} accept={accept} style={{display: 'none'}} max={limit} id="file-upload" type="file" />
      {limit > props.value.length && (<StyledLabel
        htmlFor="file-upload"
        {...dragEvents}
        sx={isDragOver ? {
          '& img': {
            opacity: 0.3,
          },
          '& p, svg': {
            opacity: 1,
          },
        } : undefined
      }>
        <Box width={600} height={100} sx={{ pointerEvents: 'none' }}>
            <Box width={600} height={100}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'absolute',
              }}
            >
              <CloudUpload fontSize="large" />
              <Typography>{labelText}</Typography>
            </Box>
        </Box>
      </StyledLabel>)}
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  )
}