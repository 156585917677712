import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { Link } from 'react-router-dom';
import { useCurrentUser } from '../../hook/useCurrentUser';
import { PasswordControl } from '../../component/ui';

interface IProps {
  isOpen: boolean;
  onClose: (code: string) => void;
}

export function CodeDialog({ isOpen, onClose }: IProps) {
  const { t } = useTranslation();
  const [signCode, setSignCode] = useState('');
  const currentUser = useCurrentUser();

  return (
    <Dialog open={isOpen} onClose={() => onClose(":abort:")}>
      <DialogTitle>{t("signCode.title")}</DialogTitle>
      <DialogContent>
        <PasswordControl
          labelKey="signCode.field"
          value={signCode}
          onChange={(value) => setSignCode(value)}
          hintKey="signCode.help"
          inputProps={{ sx: { width: "400px" }}}
        />
      </DialogContent>
      <DialogActions>
        <Link to={`/employee/${currentUser.EMAIL}`} style={{ marginRight: 'auto', marginLeft: '20px', color: 'rgba(0, 0, 0, 0.6)', textDecorationColor: 'rgba(0, 0, 0, 0.6)' }}>
          <small>{t('signCode.change')}</small>
        </Link>
        <Button onClick={() => onClose(":abort:")}>{t("button.close")}</Button>
        <Button color="success" onClick={() => onClose(signCode)}>
          {t("button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
