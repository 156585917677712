import React from "react";
import { Box, CircularProgress } from "@mui/material";

interface IProps {
  size?: number;
}

export function Loader({ size = 30 }: IProps) {
  return (
    <Box display="flex" justifyContent="center" justifyItems="center">
      <CircularProgress size={size} />
    </Box>
  );
}
