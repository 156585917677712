import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

interface IProps extends SvgIconProps {
  icon: IconDefinition;
}

export function FontAwesomeScalableIcon({ icon, ...rest }: IProps) {
    return (
      <SvgIcon {...rest}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 20"
          preserveAspectRatio="xMidYMid meet"
        >
          <FontAwesomeIcon icon={icon} color={rest.color} />
        </svg>
      </SvgIcon>
    );
}

export default FontAwesomeScalableIcon;
