import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export function useErrorHandler() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const defaultPrefix = "error.httpError.";

  return useCallback((name: string, error: any) => {
    error = error?.response ? error.response : error;
    if (error.data?.errorKey) {
      enqueueSnackbar(
        t(`error.${error.data.errorKey}`),
        { variant: "error" }
      );
      return;
    }
    if (error.data?.data?.errors?.length) {
      enqueueSnackbar(
        t(`error.api.${error.data.data.errors[0].code}`, { nsSeparator: '|' }),
        { variant: "error" }
      );
      return;
    }

    if (error && error.status === 400) {
      console.error(name, "validation error", error.data);
      enqueueSnackbar(
        t(`${defaultPrefix}badRequest`),
        { variant: "error" }
      );
      return;
    }

    if (error && error.status === 401) {
      console.error(name, "authorize error");
      enqueueSnackbar(
        t(`${defaultPrefix}unauthorized`),
        { variant: "error" }
      );
      return;
    }

    if (error && error.status === 403) {
      console.error(name, "access error");
      enqueueSnackbar(
        t(`${defaultPrefix}accessDenied`),
        { variant: "error" }
      );
      return;
    }

    if (error && error.status === 404) {
      console.error(name, "not found error");
      enqueueSnackbar(
        t(`${defaultPrefix}notFound`),
        { variant: "error" }
      );
      return;
    }

    if (error && error.status === 500) {
      console.error(name, error);
      enqueueSnackbar(
        t(`${defaultPrefix}internal`),
        { variant: "error" }
      );
      return;
    }
    console.error(name, error);
  }, [t, enqueueSnackbar]);
}
