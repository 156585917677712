import { MediaType } from './file.media.type';

export const DocumentMediaTypes = [
  MediaType.TEXT,
  MediaType.PDF,
  MediaType.DOC,
  MediaType.DOCX,
  MediaType.PAGES,
  MediaType.XLS,
  MediaType.XLSX,
  MediaType.NUMBERS,
  MediaType.RTF,
  MediaType.ODT,
  MediaType.WPD,
  MediaType.JPG,
  MediaType.PNG,
  MediaType.TIFF,
  MediaType.SVG,
  MediaType.HTML,
  MediaType.XHTML,
  MediaType.XML,
  MediaType.XML_OBSOLETE,
];