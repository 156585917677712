import {
  FilterAutocompleteItem,
  FilterDateRangeItem, FilterSelectItem,
  FilterTextItem, GUID_FILTER,
} from '../../models/filter';
import { useOptions } from '../../hook/UseOptions';
import { SignxDocumentStatus } from '../../models/enum';
import { EntityQueryKey } from '../../enum/query.keys';
import { AutocompleteService } from '../../service/autocomplete.service';

export function useFilter() {
  const statusOptions = useOptions(SignxDocumentStatus, 'documentHistory.DOCUMENT_STATUS_CHANGE.')
  return [
    new FilterTextItem({
      field: 'search',
      labelKey: 'documentList.field.title',
    }),
    new FilterSelectItem({
      field: 'status',
      labelKey: 'documentList.field.status',
      options: statusOptions,
    }),
    new FilterAutocompleteItem({
      field: 'initiator',
      labelKey: 'documentList.field.initiator',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.User,
    }),
    new FilterAutocompleteItem({
      field: 'counterparty',
      labelKey: 'documentList.field.signer',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.User,
    }),
    new FilterDateRangeItem({
      field: 'createDate',
      labelKey: 'common.field.createDate',
    }),
    new FilterDateRangeItem({
      field: 'updateDate',
      labelKey: 'common.field.updateDate',
    }),
    GUID_FILTER,
  ];
}
