import { useParams, useNavigate } from "react-router-dom";
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Loader } from "../../component/loader";
import { useMutation, useQuery } from "react-query";
import { DocumentService } from "../../service/document.service";
import { Rest } from "../../rest";
import PageTitle from "../../component/pageTitle";
import { useTranslation } from "react-i18next";
import { Document, Page, PDFPageProxy } from 'react-pdf';
import {Box, Button, Grid, IconButton, Stack, Tab, Tabs} from "@mui/material";
import { InfoTab } from "./info.tab";
import { HistoryTab } from "./history.tab";
import { FilesTab } from "./files.tab";
import {
  SignxDocumentStatus,
  SignxSignerStatusEnum,
  SignxSignTypeEnum,
} from "../../models/enum";
import { useCurrentUser } from "../../hook/useCurrentUser";
import { useErrorHandler } from "../../hook/useErrorHandler";
import { useSnackbar } from "notistack";
import { Signer } from "../../models/interfaces/signer";
import { LoadingButton } from "@mui/lab";
import { DocumentDetails as IDocumentDetails } from "../../models/interfaces";
import { RejectDialog } from "./reject.dialog";
import { useConfirm } from 'material-ui-confirm';
import {ArrowLeft, ArrowRight} from "@mui/icons-material";
import { CodeDialog } from './code.dialog';

export function DocumentDetails() {
  const { guid } = useParams<{ guid: string }>();
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCode, setIsOpenCode] = useState(false);
  const [pageWidth, setPageWidth] = useState<number>();
  const currentUser = useCurrentUser();
  const handleError = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const prepareFile = useCallback((document?: IDocumentDetails) => {
    if (document?.originalFile?.mediaType === "application/pdf") {
      const bytes = atob(document.originalFile.content);
      let length = bytes.length;
      let out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      return URL.createObjectURL(new Blob([out], { type: "application/pdf" }));
    }
    return undefined;
  }, []);

  const { data: document, refetch } = useQuery(
    `document_details_${guid}`,
    () => DocumentService.details(guid!),
    {
      onSuccess: (data) => {
        setPdf(prepareFile(data));
      },
      onError: (error) => handleError("Document details", error),
      onSettled: () => {
        // Rest.resizeFrame(); в облаке косячит
        setLoading(false)
      },
    }
  );

  const [loading, setLoading] = useState(!document);
  const [pdf, setPdf] = useState<string | undefined>(prepareFile(document));
  const [pdfPage, setPdfPage] = useState(1);
  const [pdfPages, setPdfPages] = useState<number>();
  const onPdfLoad = (e: any) => {
    setPdfPages(e.numPages);
  };
  const onPageLoad = (page: PDFPageProxy) => {
    if (pdfRef.current) {
      setPageWidth(Math.min(page.originalWidth, pdfRef.current.offsetWidth - 20))
    } else {
      setPageWidth(undefined)
    }
  };

  const employee = useMemo(() => {
    if (!document || !currentUser) return null;
    return (
      document.signers?.find((item) => item.employee.id === currentUser.ID) ??
      null
    );
  }, [currentUser, document]);

  const { mutateAsync: signxSign } = useMutation(
    async ({
      document,
      employee,
      signCode,
    }: {
      document: IDocumentDetails;
      employee: Signer;
      signCode?: string;
    }) => {
      setLoading(true);
      try {
        await DocumentService.sign(
          document.uuid,
          document.guid,
          employee.employee.uuid,
          employee.type,
          signCode,
        );
        enqueueSnackbar(t("documentDetails.signSuccess"), { variant: "success" });
      } catch (e) {
        Rest.scrollParentWindow();
        handleError("sign", e);
      } finally {
        await refetch();
        setLoading(false);
      }
    }
  );

  const sign = useCallback(
    async (document: IDocumentDetails, employee: Signer | null) => {
      if (!employee) {
        enqueueSnackbar(t("error.noEmployee"), { variant: "error" });
        return null;
      }

      switch (employee?.type) {
        case SignxSignTypeEnum.SIGNX:
          if (currentUser.apiSettings['SIGN_CODE_APPLICATION'] !== 'DIRECT' || !currentUser.apiSettings['SIGN_CODE_APPLICATION']) {
            setIsOpenCode(true);
            return;
          } else {
            return await signxSign({ document, employee });
          }
        case SignxSignTypeEnum.TOKEN:
        case SignxSignTypeEnum.TOKEN_TSP:
        case SignxSignTypeEnum.TOKEN_LONG:
          navigate(`/token-sign`, {
            state: {
              document,
              employee: employee.employee.uuid,
              type: employee.type,
            }
          });
          return null;
        default:
          enqueueSnackbar(t("error.unsupportedSignType"), { variant: "error" });
          return null;
      }
    },
    [t, enqueueSnackbar, signxSign, navigate, currentUser]
  );

  const { mutateAsync: revoke } = useMutation(async () => {
    setLoading(true);
    try {
      await DocumentService.revoke(document!.uuid, document!.owner.uuid);
    } catch (e) {
      Rest.scrollParentWindow();
      handleError('revoke', e);
    } finally {
      await refetch();
      setLoading(false);
    }
  });

  const revokeConfirm = useCallback(
    () =>
      confirm({ description: t('confirm.revoke') }).then(() =>
        revoke(),
      ),
    [t, revoke, confirm],
  );

  const pdfRef = useRef<HTMLDivElement>(null);



  return !document ? (
      loading ? <Loader /> : <></>
  ) : (
    <Box>
      <PageTitle text={document.title} />
      <Grid container spacing={2}>
        <Grid item xs={8} ref={pdfRef}>
          {pdf ? (
              <>
                <Stack direction="row" spacing={1} style={{ marginTop: '6px' }}>
                  <IconButton disabled={pdfPage < 2} onClick={() => setPdfPage((prev) => prev - 1)}>
                    <ArrowLeft/>
                  </IconButton>
                  <Box sx={{ alignItems: "center", display: "flex" }}>{pdfPage} / {pdfPages}</Box>
                  <IconButton disabled={pdfPage === pdfPages} onClick={() => setPdfPage((prev) => prev + 1)}>
                    <ArrowRight/>
                  </IconButton>
                </Stack>
                <Document file={pdf} onLoadSuccess={onPdfLoad} className="bordered">
                  <Page className="d-inline-block"
                        pageNumber={pdfPage}
                        onLoadSuccess={onPageLoad}
                        width={pageWidth} />
                </Document>
              </>
          ) : (
            <h4>{t("documentDetails.previewDenied")}</h4>
          )}
          <Stack direction="row" spacing={2} mt={2}>
            {document.status === SignxDocumentStatus.SIGNING &&
              document.signers.some(
                (item) => item.status === SignxSignerStatusEnum.SENT && item.employee.id === currentUser?.ID
              ) && (
                <>
                  <LoadingButton loading={loading} variant="outlined" color="success" onClick={() => sign(document, employee)}>
                    {t("documentDetails.button.sign")}
                  </LoadingButton>
                  {!document.rejectForbidden &&  <Button disabled={loading} variant="outlined" color="error" onClick={() => setIsOpen(true)}>
                    {t("documentDetails.button.reject")}
                  </Button>}
                </>
              )}
            {document.status === SignxDocumentStatus.SIGNING && document.owner.id === currentUser.ID && (
              <LoadingButton loading={loading} variant="outlined" color="warning" onClick={() => revokeConfirm()}>
                {t("documentDetails.button.revoke")}
              </LoadingButton>
            )}
            {document.status === SignxDocumentStatus.DRAFT && document.owner.id === currentUser.ID && (
              <Button variant="outlined" color="error">
                {t("documentDetails.button.delete")}
              </Button>
            )}
          </Stack>
        </Grid>
        <Grid item xs={4}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
              <Tabs
                value={tab}
                onChange={(e, val) => setTab(val)}
                aria-label="basic tabs example"
                scrollButtons="auto"
                variant="scrollable"
              >
                <Tab className="text-transform-none" label={t("documentDetails.tabs.info")} />
                <Tab className="text-transform-none" label={t("documentDetails.tabs.history")} />
                <Tab className="text-transform-none" label={t("documentDetails.tabs.files")} />
              </Tabs>
            </Box>
            <InfoTab index={0} tab={tab} document={document} />
            <HistoryTab index={1} tab={tab} document={document} />
            <FilesTab index={2} tab={tab} document={document} />
        </Grid>
      </Grid>
      {employee?.employee.uuid && ([
        <RejectDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          document={document}
          employee={employee.employee.uuid}
        />,
        (employee?.type === SignxSignTypeEnum.SIGNX && (currentUser.apiSettings['SIGN_CODE_APPLICATION'] !== 'DIRECT' || !currentUser.apiSettings['SIGN_CODE_APPLICATION'])) ? (
          <CodeDialog
            isOpen={isOpenCode}
            onClose={(signCode) => {
              setIsOpenCode(false);
              if (signCode !== ':abort:') {
                return signxSign({ document, employee, signCode });
              }
            }}
          />
        ) : null,
      ])}
    </Box>
  );
}
