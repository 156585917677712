import ModelValidator from './model.validator';
import validator from 'validator';
import isStrongPassword = validator.isStrongPassword;

class CSignCodeUpdateValidator extends ModelValidator<string> {
  validate(model: string): string[] {
    const errors: string[] = [];
    this.checkLength(model, errors, 255);
    this.checkRequired(model, errors);
    this.check(model, errors, (val) => isStrongPassword(val, { minSymbols: 0 }));
    return errors;
  }
}

export const SignCodeUpdateValidator = new CSignCodeUpdateValidator();