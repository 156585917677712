import React, { useCallback, useContext, useRef, useState } from 'react';
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Rest } from '../../rest';
import { useErrorHandler } from '../../hook/useErrorHandler';
import { UserService } from '../../service/user.service';
import { useCurrentUser } from '../../hook/useCurrentUser';
import { AppContext } from '../../context/app.context';
import { useNavigate } from 'react-router-dom';


export function PrivateDataConsentForm() {
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const [loading, setLoading] = useState(false);
  const user = useCurrentUser();
  const { setHeaderVisibility, setPDConsent } = useContext(AppContext);
  const navigate = useNavigate();

  const sign = useCallback(async () => {
    setLoading(true);
    try {
      await UserService.setPDConsent(user.ID, user.EMAIL);
      Rest.setUserOption('PD', 'true');
      setHeaderVisibility(true);
      setPDConsent();
      navigate('/', { replace: true });
    } catch (e) {
      Rest.scrollParentWindow();
      handleError("sign", e);
    } finally {
      setLoading(false);
    }
  }, [navigate, handleError, user, setHeaderVisibility, setPDConsent]);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const fitHeight = useCallback((iframe: HTMLIFrameElement | null) => {
      if (iframe) {
        iframe.style.height = `${iframe.contentWindow?.document.documentElement.scrollHeight}px`;
        for (const a of iframe.contentWindow?.document.getElementsByTagName('a') ?? []) {
          a.setAttribute('target', '_blank');
        }
      }
    },
    []);

  return (
    <Box>
      <h2>{t("pdConsent.title")}</h2>
      <Typography sx={{ my: 2 }}>
        <span dangerouslySetInnerHTML={{ __html: t('pdConsent.description.1') }} /><br/>
        {t("pdConsent.description.2")}<br/>
        <span dangerouslySetInnerHTML={{ __html: t('pdConsent.description.3') }} />
      </Typography>
      <br/>
      <iframe ref={iframeRef} title="politic_kedo"
              style={{ width: '780px', border: 'none', display: 'block', margin: 'auto' }}
              src={`${process.env.PUBLIC_URL}/politic_kedo.html`} onLoad={() => fitHeight(iframeRef.current)}
      />
      <Stack direction="row-reverse" spacing={2} mt={2}>
        <LoadingButton
          loading={loading}
          variant="outlined"
          color="success"
          onClick={() => sign()}
        >
          {t("pdConsent.button")}
        </LoadingButton>
      </Stack>
    </Box>
  );
}
