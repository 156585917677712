import { ListRequestDto } from '../models/interfaces';
import { HttpClient } from './http.client';
import { Employee } from '../models/interfaces/employee';
import { Rest } from '../rest';

class CUserService {
  async checkEmployee(email: string, client: string) {
    try {
      return (
        await HttpClient.post<boolean>(`/user/checkEmployee`, { email, client })).data;
    } catch (e) {
      return false;
    }
  }

  async list(data: ListRequestDto<any>) {
    return (await HttpClient.post(`/user/list`, data)).data
  }

  async getPDConsent(bxId: string, email: string) {
    try {
      return (await HttpClient.post('user/get-pd-consent', { bxId, email })).data;
    } catch (e) {
      return null;
    }
  }

  async setPDConsent(bxId: string, email: string) {
    try {
      return (await HttpClient.post('user/set-pd-consent', { bxId, email })).data;
    } catch (e) {
      return false;
    }
  }

  async updateSignCode(email: string, signCode: string | null) {
    await HttpClient.post<boolean>(`/user/sign-code`, { email, signCode });
  }

  async details(email: string) {
    return (await HttpClient.post<Employee>(`/user/employee`, { email })).data;
  }

  async getCurrentUser() {
    try {
      return (await HttpClient.post(`/user/current`)).data;
    } catch (e) {
      console.error('Failed to get full user, getting BX only', e);
      return (await Rest.callMethod('user.current', {})).items?.[0] ?? null;
    }
  }
}

export const UserService = new CUserService();
