import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentListItem } from '../../models/interfaces';
import { TableColumn, TableLinkColumn } from '../../component/DataTable/table.model';
import {
  Avatar,
  AvatarGroup, Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Link,
  Stack,
  styled,
  Tooltip,
  useTheme,
} from '@mui/material';
import { Rest } from '../../rest';
import Utils from '../../utils';
import DownloadIcon from '@mui/icons-material/Download';
import { DownloadLink } from '../../component/DownloadLink';
import { DocumentFileType, SignxDocumentStatus, SignxSignerStatusEnum } from '../../models/enum';
import { DocumentService } from '../../service/document.service';
import CloseIcon from '@mui/icons-material/Close';
import { Signer } from '../../models/interfaces/signer';
import { useCurrentUser } from '../../hook/useCurrentUser';

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiDialog-container": {
    alignItems: "flex-start"
  }
}));

function SignerBlock({ signer }: { signer: Signer }) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={2}>
      <Avatar src={signer.employee.avatar}/>
      <Stack direction="column">
        {signer.employee.id ? <Link href={`https://${Rest.domain}/company/personal/user/${signer.employee.id}/`} target="_blank">{signer.employee.name}</Link> : signer.employee.name}
        <Stack direction="row" alignItems="center" spacing={1} color={theme.palette[Utils.getColor(signer.status)].main}>
          <Icon className="material-symbols-outlined" fontSize="small">{Utils.getIcon(signer.status)}</Icon>
          <Tooltip title={signer.comment} placement="right-start">
            <small style={{
              borderBottom: signer.comment ? '1px dashed' : undefined,
              cursor: signer.comment ? 'pointer' : undefined,
            }}>{t(`signerStatus.${signer.status}`)}</small>
          </Tooltip>
        </Stack>
        <Box sx={{ marginTop: '3px' }}>
          <small style={{ background: theme.palette.grey['200'], color: theme.palette.grey.A700, padding: '3px 6px' }}>{t(`signType.${signer.type}`)}</small>
        </Box>
      </Stack>
    </Stack>
  )
}

export function useColumns() {
  const { t } = useTranslation();
  const [dialogState, setDialogState] = useState<Record<string, boolean>>({})
  const opened = (guid: string) => dialogState[guid];
  const handleClose = (guid: string) => setDialogState((prev) => ({ ...prev, [guid]: false }));
  const theme = useTheme();
  const currentUser = useCurrentUser();

  return useMemo(
    () => [
      new TableLinkColumn<DocumentListItem>({
        headerName: t('documentList.field.title'),
        field: 'guid',
        text: (row) => row.title,
        linkProps: (row) => ({ to: `/docflowinternal/details/${row.guid}` }),
        minWidth: 200,
      }),
      new TableColumn<DocumentListItem>({
        headerName: t('documentList.field.initiator'),
        field: 'initiator',
        renderCell: ({ row }) =>
          row.initiator ? (<SignerBlock signer={row.initiator}/>) : null,
        width: 270,
      }),
      new TableColumn<DocumentListItem>({
        headerName: t('documentList.field.signers'),
        field: 'signers',
        renderCell: ({ row }) => {
          if (row.signers?.length === 1) {
            return (<SignerBlock signer={row.signers[0]}/>)
          } else if (row.signers?.length) {
            return (<>
              <AvatarGroup max={4} sx={{mr: 1}}>
                {row.signers.map((signer) => <Avatar sx={{ width: 30, height: 30 }} src={signer.employee.avatar}/>)}
              </AvatarGroup>
              <small style={{ cursor: 'pointer' }}><Link onClick={() => {
                setDialogState((prev) => ({ ...prev, [row.guid]: true }));
                Rest.scrollParentWindow(0);
              }}>{t('documentList.showSigners')}</Link></small>
              <StyledDialog onClose={() => handleClose(row.guid)} open={opened(row.guid)} scroll="paper">
                <DialogTitle sx={{ m: 0, p: 2 }}>
                  {t('documentList.field.signers')}
                    <IconButton aria-label="close" onClick={() => handleClose(row.guid)}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                  <Stack direction="column" spacing={2}>
                    {row.signers.map((signer) => <SignerBlock signer={signer}/>)}
                  </Stack>
                </DialogContent>
              </StyledDialog>
            </>)
          }

          return null;
        },
        width: 270,
      }),
      new TableColumn<DocumentListItem>({
        headerName: t('documentList.field.status'),
        field: 'status',
        renderCell: ({ row }) =>
          <Stack direction="row" alignItems="center" spacing={1} color={theme.palette[Utils.getColor(row.status)].main}>
            <Icon className="material-symbols-outlined" fontSize="small">{Utils.getIcon(row.status)}</Icon>
            <span>{t(`documentHistory.DOCUMENT_STATUS_CHANGE.${row.status}`)}</span>
          </Stack>,
        width: 150,
      }),
      new TableColumn<DocumentListItem>({
        headerName: t('documentList.field.crmLink'),
        field: 'crmLink',
        renderCell: ({ row }) =>
          row.crmEntity ? (<Link
              target="_blank"
              href={Utils.getEntityLink(row.crmEntity, currentUser)}
            >
              {row.crmEntity.view}
            </Link>) : null,
        width: 200,
      }),
      new TableColumn<DocumentListItem>({
        headerName: t('documentList.field.file'),
        field: 'file',
        renderCell: ({ row }) =>
          <Stack direction="column" spacing={1}>
            <Stack direction="row" alignItems="center">
              <DownloadIcon color="primary" />
              <DownloadLink downloader={() => DocumentService.download(row.guid, DocumentFileType.ORIGINAL)}>
                {t("documentFile.ORIGINAL")}
              </DownloadLink>
            </Stack>
            {row.signers
              ?.filter((item) => item.status === SignxSignerStatusEnum.SIGNED)
              .map((item) => (
                <Stack direction="row" alignItems="center">
                  <DownloadIcon color="primary" />
                  <DownloadLink downloader={() => DocumentService.download(row.guid, DocumentFileType.SIGNATURE, item.employee.uuid)}>
                    {t("documentFile.SIGNATURE", [item.employee.name])}
                  </DownloadLink>
                </Stack>
              ))}
            {row.status === SignxDocumentStatus.SIGNED && (
              <>
                <Stack direction="row" alignItems="center">
                  <DownloadIcon color="primary" />
                  <DownloadLink downloader={() => DocumentService.download(row.guid, DocumentFileType.REPORT)}>
                    {t("documentFile.REPORT")}
                  </DownloadLink>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <DownloadIcon color="primary" />
                  <DownloadLink downloader={() => DocumentService.download(row.guid, DocumentFileType.PRINT)}>
                    {t("documentFile.PRINT")}
                  </DownloadLink>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <DownloadIcon color="primary" />
                  <DownloadLink downloader={() => DocumentService.download(row.guid, DocumentFileType.ARCHIVE)}>
                    {t("documentFile.ARCHIVE")}
                  </DownloadLink>
                </Stack>
              </>
            )}
          </Stack>,
        width: 350,
      }),
    ],
    [currentUser, dialogState],
  );
}
