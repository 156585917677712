import React from "react";
import { SelectItem } from '../models/interfaces';

const defaultContext = {
    settings: {
        ExternalSystemClientId: {
            Value: ''
        },
        AdminList: {
            Value: []
        },
        NotifySigner: {
            Value: 'N'
        },
    },
    appVersion: 0,
    isApiKeyLegal: false,
    getCurrentUser: () => ({} as any),
    initAppSettings: async (): Promise<boolean> => { return true; },
    setAppSettings: async (key: string, data: any, keyAuth?: any): Promise<boolean> => { return true; },
    updateComplete: () => { },
    access: (key: string) => false,
    setHeaderVisibility: (state: boolean) => { },
    setPDConsent: () => { },
    featureFlag: {} as Record<string, boolean>,
    currentClient: undefined,
    setCurrentClient: (val: string) => ({}),
    apiClients: [] as SelectItem[],
    availableClients: [] as SelectItem[],
    toggleApiInfoDialog: (val: boolean) => {},
};

export const AppContext = React.createContext(defaultContext);
